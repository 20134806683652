import Cta from 'components/callToActionNextPage'
import SvgWrapper from 'components/svgWrapper'
import React, { Fragment, PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import track from 'react-tracking'
import { Grid } from 'styles/flexboxgrid'
import removeForm from 'utils/removeForm'
import Section1 from './components/section1'
import Section2 from './components/section2'
import Section3 from './components/section3'

const shapes = [
  // 0
  {
    path:
      'M500,261 C500,363 407.110338,329.983974 282.110338,321.983974 C157.110338,313.983974 30.1095507,352.696815 5.11033833,296.983974 C-19.888874,241.271133 53.8391981,174.406505 75.1103383,146.983974 C101.165855,113.393474 123.978219,84.5864503 148.110338,28.9839739 C172.242458,-26.6185024 310.110338,1.98397394 348.110338,78.9839739 C386.110338,155.983974 500,159 500,261 Z',
    pathAlt:
      'M500,250 C520,353.016026 401.220677,291.271133 282.110338,321.983974 C163,352.696815 30.1095507,352.696815 5.11033833,296.983974 C-19.888874,241.271133 31,167.213363 75.1103383,146.983974 C122.289305,125.347285 123.978219,84.5864503 148.110338,28.9839739 C172.242458,-26.6185024 289.110338,10.9839739 348.110338,78.9839739 C407.110338,146.983974 480,146.983974 500,250 Z',
    pathTransition: [
      {
        value:
          'M308.3125,293.758977 C298.3125,303.758977 294.3125,312.758977 271.3125,318.758977 C248.3125,324.758977 227.3125,321.758977 203.3125,303.758977 C179.3125,285.758977 174.3125,264.758977 180.3125,230.758977 C186.3125,196.758977 213.3125,184.758977 230.3125,179.758977 C247.3125,174.758977 299.3125,178.758977 314.3125,213.758977 C329.3125,248.758977 318.3125,283.758977 308.3125,293.758977 Z'
      },

      {
        value:
          'M290.256831,303 C280.256831,312 272.912355,318 255.211521,318 C237.510686,318 221.256831,312 213.256831,303 C205.256831,294 188.256831,283 194.256831,249 C200.256831,215 232.16621,182 255.211521,182 C278.256831,182 306.256831,227 306.256831,255 C306.256831,283 300.256831,294 290.256831,303 Z'
      },
      {
        value:
          'M290.256831,303 C280.256831,312 272.912355,318 255.211521,318 C237.510686,318 221.256831,312 213.256831,303 C205.256831,294 188.256831,283 194.256831,249 C200.256831,215 232.16621,182 255.211521,182 C278.256831,182 306.256831,227 306.256831,255 C306.256831,283 300.256831,294 290.256831,303 Z'
      },
      {
        value:
          'M302.381888,313 C292.381888,322 283.381888,343 254.336578,343 C225.291267,343 210.381888,322 202.381888,313 C194.381888,304 179.381888,263 185.381888,229 C191.381888,195 226.645633,158 249.690944,158 C272.736255,158 315.381888,201 315.381888,229 C315.381888,257 312.381888,304 302.381888,313 Z'
      },
      {
        value:
          'M370.401417,356 C345.401417,385 310.230852,396 249.956278,396 C189.681704,396 149.401417,383 129.401417,356 C109.401417,329 95.4014171,286 101.401417,252 C107.401417,218 133.401417,105 246.710473,105 C360.019529,105 399.401417,229 399.401417,257 C399.401417,285 395.401417,327 370.401417,356 Z'
      }
    ],
    leftColumn: true,
    scaleX: { xs: 2.5, sm: 2.5, lg: 3.5 },
    scaleY: { xs: 1.5, sm: 2.5, lg: 3.5 },
    tx: { xs: 30, sm: 40, lg: 20 },
    ty: { xs: 0, lg: 0 },
    rotate: 0,

    animation: {
      path: {
        duration: 2000,
        easing: 'easeInElastic',
        elasticity: 600
      },
      svg: {
        duration: 5000,
        easing: 'easeOutElastic',
        elasticity: 300
      }
    }
  }, // 1
  {
    path:
      'M356,191 C356,240 187,234 325,259 C463,284 369,340 271,312 C173,284 112,357 94,297 C76,237 222,255.575507 141,222 C60,188.424493 141,148 220,181 C299,214 356,142 356,191 Z',
    pathAlt:
      'M356,191 C356,259 224,245 325,259 C426,273 412,345 271,312 C130,279 130,356 94,297 C58,238 188,253 141,222 C94,191 115,147 220,181 C325,215 356,123 356,191 Z',
    scaleX: 1.5,
    scaleY: 1.7,
    rotate: 0,
    tx: 10,
    ty: 10,
    animation: {
      path: {
        duration: 2000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 2000,
        easing: 'easeOutElastic'
      }
    }
  }, // 2
  {
    path:
      'M317.209124,219.754579 C216.209124,267.754579 232.209124,280.754579 302.209124,255.754579 C372.209124,230.754579 425.209124,294.754579 322.209124,330.754579 C219.209124,366.754579 66.2091241,375.754579 181.209124,325.754579 C296.209124,275.754579 137.209124,324.754579 122.209124,294.754579 C107.209124,264.754579 170.209124,245.754579 262.209124,211.754579 C354.209124,177.754579 418.209124,171.754579 317.209124,219.754579 Z',
    pathAlt:
      'M317.209124,219.754579 C216.209124,267.754579 262.209124,262 302.209124,255.754579 C342.209124,249.509158 405.418248,274.509158 322.209124,330.754579 C239,387 54,402 181.209124,325.754579 C308.418248,249.509158 151,319 136,289 C121,259 191,237 262.209124,211.754579 C333.418248,186.509158 418.209124,171.754579 317.209124,219.754579 Z',
    // pathTransition:
    //     [
    //       {
    //         value: 'M275.635942,211 C275.635942,260 266.635942,240 282.635942,316 C298.635942,392 289.493045,458 238.635942,458 C187.77884,458 221.635942,386 214.635942,333 C207.635942,280 213.572812,252 221.635942,196 C229.699072,140 238.635942,43 257.635942,43 C276.635942,43 275.635942,162 275.635942,211 Z', duration: 300
    //       }
    //     ],
    scaleX: 1.8,
    scaleY: 1.7,
    rotate: -20,
    tx: 10,
    ty: 10,
    animation: {
      path: {
        duration: 2000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 2000,
        easing: 'easeOutElastic'
      }
    }
  }, // 3
  {
    path:
      'M358.910757,261.518127 C358.910757,329.159454 358.910757,329.159454 298.785133,362.228547 C238.659509,395.29764 183.043308,375.756812 152.980496,362.228547 C122.917684,348.700282 121.006982,209.543331 152.980496,168.323411 C192.145601,117.832167 222.124963,121.726053 261.206618,121.726053 C300.288274,121.726053 332.976073,112.575215 358.910757,144.273161 C384.84544,175.971108 358.910757,193.876801 358.910757,261.518127 Z',
    pathAlt:
      'M358.910757,261.518127 C358.910757,329.159454 358.910757,329.159454 298.785133,362.228547 C238.659509,395.29764 183.043308,375.756812 152.980496,362.228547 C122.917684,348.700282 121.006982,209.543331 152.980496,168.323411 C192.145601,117.832167 222.124963,121.726053 261.206618,121.726053 C300.288274,121.726053 332.976073,112.575215 358.910757,144.273161 C384.84544,175.971108 358.910757,193.876801 358.910757,261.518127 Z',
    scaleX: 1.7,
    scaleY: 1,
    rotate: -20,
    tx: 0,
    ty: 20,
    animation: {
      path: {
        duration: 2000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 3000,
        easing: 'easeOutElastic'
      }
    }
  }, // 4
  {
    path:
      'M488,364 C488,466 417,400 292,392 C167,384 51.9992123,438.712841 27,383 C2.00078765,327.287159 37.7288598,254.422532 59,227 C85.0555171,193.4095 125.220677,193 148.110338,117 C171,41 325,88 363,165 C401,242 488,262 488,364 Z',
    pathAlt:
      'M488,364 C488,466 417,400 292,392 C167,384 51.9992123,438.712841 27,383 C2.00078765,327.287159 37.7288598,254.422532 59,227 C85.0555171,193.4095 125.220677,193 148.110338,117 C171,41 325,88 363,165 C401,242 488,262 488,364 Z',
    scaleX: 0.8,
    scaleY: 0.5,
    rotate: -20,
    tx: 0,
    ty: 0,
    animation: {
      path: {
        duration: 2000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 3000,
        easing: 'easeOutElastic'
      }
    }
  }, // 5
  {
    path:
      'M358.910757,261.518127 C358.910757,329.159454 358.910757,329.159454 298.785133,362.228547 C238.659509,395.29764 183.043308,375.756812 152.980496,362.228547 C122.917684,348.700282 121.006982,209.543331 152.980496,168.323411 C192.145601,117.832167 222.124963,121.726053 261.206618,121.726053 C300.288274,121.726053 332.976073,112.575215 358.910757,144.273161 C384.84544,175.971108 358.910757,193.876801 358.910757,261.518127 Z',
    pathAlt:
      'M358.910757,261.518127 C358.910757,329.159454 358.910757,329.159454 298.785133,362.228547 C238.659509,395.29764 183.043308,375.756812 152.980496,362.228547 C122.917684,348.700282 121.006982,209.543331 152.980496,168.323411 C192.145601,117.832167 222.124963,121.726053 261.206618,121.726053 C300.288274,121.726053 332.976073,112.575215 358.910757,144.273161 C384.84544,175.971108 358.910757,193.876801 358.910757,261.518127 Z',
    scaleX: { xs: 3, md: 5, lg: 2.5 },
    scaleY: { xs: 3.3, sm: 2, md: 1.5 },
    tx: { xs: -5, md: -10 },
    ty: { xs: 25, sm: 15, md: -5 },
    rotate: 0,
    animation: {
      path: {
        duration: 2000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 3000,
        easing: 'easeOutElastic'
      }
    }
  }, // 6
  {
    path:
      'M358.910757,261.518127 C358.910757,329.159454 358.910757,329.159454 298.785133,362.228547 C238.659509,395.29764 183.043308,375.756812 152.980496,362.228547 C122.917684,348.700282 121.006982,209.543331 152.980496,168.323411 C192.145601,117.832167 222.124963,121.726053 261.206618,121.726053 C300.288274,121.726053 332.976073,112.575215 358.910757,144.273161 C384.84544,175.971108 358.910757,193.876801 358.910757,261.518127 Z',
    pathAlt:
      'M358.910757,261.518127 C358.910757,329.159454 358.910757,329.159454 298.785133,362.228547 C238.659509,395.29764 183.043308,375.756812 152.980496,362.228547 C122.917684,348.700282 121.006982,209.543331 152.980496,168.323411 C192.145601,117.832167 222.124963,121.726053 261.206618,121.726053 C300.288274,121.726053 332.976073,112.575215 358.910757,144.273161 C384.84544,175.971108 358.910757,193.876801 358.910757,261.518127 Z',
    scaleX: 3,
    scaleY: 3,
    rotate: 0,
    tx: 0,
    ty: 0,
    animation: {
      path: {
        duration: 2000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 2000,
        easing: 'easeOutElastic'
      }
    }
  }
]

@track(
  {
    page: 'Home'
  },
  { dispatchOnMount: true }
)
class Home extends PureComponent {
  constructor (props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      watchers: {},
      currentItem: {
        item: {},
        index: 0
      }
    }
  }

  getItemInViewport = (url) => (index, watcher) => {
    this.setState(prevState => {
      const nextItemIndexOffset =
        prevState.currentItem.index === index
          ? 0
          : index > prevState.currentItem.index
            ? 1
            : -1
      const nextIndex =
        index === 0 ? 0 : prevState.currentItem.index + nextItemIndexOffset
      // if (nextIndex === 0 && window.history.state !== url && window.history.state !== 'about-me') {
      //   window.history.pushState(url, 'Massimo Fortarezza | Solution Architect and Full Stack Engineer', url)
      // }
      return {
        ...prevState,
        currentItem: {
          item: prevState.watchers[nextIndex] || watcher,
          index: nextIndex
        }
      }
    })
  }

  registerWatcher = (index, watcher) => {
    this.setState((previousState, currentProps) => {
      return {
        ...previousState,
        watchers: {
          ...previousState.watchers,
          [index]: watcher
        }
      }
    })
  }

  removeCurrentItemFromViewport = index => {}

  render () {
    removeForm()
    return (
      <Fragment>
        <Helmet
          title="Massimo Fortarezza | Solution Architect and Full Stack Engineer"
          meta={[
            {
              name: 'description',
              content:
                'Developing and engineering systems since 2016'
            },
            {
              name: 'keywords',
              content:
                'Full stack Engineer - AWS Solution Architect - AWS Cloud - NodeJS - ReactJS - React Native'
            }
          ]}
        />
        <Grid>
          <SvgWrapper
            shapes={shapes}
            itemsInViewport={{
              current: this.state.currentItem
            }}
          />
        </Grid>

        <Section1
          getItemInViewport={this.getItemInViewport('about-me')}
          registerWatcher={this.registerWatcher}
          removeCurrentItemFromViewport={this.removeCurrentItemFromViewport}
        />
        <Grid>
          <Section2
            getItemInViewport={this.getItemInViewport('skills')}
            registerWatcher={this.registerWatcher}
            removeCurrentItemFromViewport={this.removeCurrentItemFromViewport}
          />
        </Grid>
        <Section3
          getItemInViewport={this.getItemInViewport('certifications')}
          registerWatcher={this.registerWatcher}
          removeCurrentItemFromViewport={this.removeCurrentItemFromViewport}
        />
        <Grid>
          {/* <FeedbackShowcase
            getItemInViewport={this.getItemInViewport}
            registerWatcher={this.registerWatcher}
            removeCurrentItemFromViewport={this.removeCurrentItemFromViewport}
          /> */}
          {/* <ClientsShowcase /> */}

          <Cta title="If you want to know on which projects I worked then" text="Contact me" to="https://www.linkedin.com/in/massimo-fortarezza/" />
        </Grid>
      </Fragment>
    )
  }
}

export default Home

import React from 'react'
import { STC } from './style'
import Pizza from 'assets/images/pizza.png'

export default ({ facebook_to, instagram_to, dribbble_to }) => (
  <STC.Information between middle>
    <STC.ContainerLogo xs="12" lg="4">
      <STC.Span>Working area</STC.Span>
      <STC.Text>
        Remote - Milan - Lugano
      </STC.Text>
      {/* <STC.Text
        as="a"
        href="https://www.iubenda.com/privacy-policy/70472129"
        className="iubenda-white iubenda-embed"
        title="Privacy Policy"
      >
        Privacy Policy
      </STC.Text> */}
    </STC.ContainerLogo>

    <STC.ContainerSocial xs="12" lg="4">
      <STC.Span>In love with</STC.Span>
      <STC.Text as="span" >
        <img src={Pizza} style={{height: '3rem', marginBottom: '-1rem'}} />
      </STC.Text>
      <STC.Circle>•</STC.Circle>
      <STC.Text as="span">
        Every kind of sport
      </STC.Text>
      <STC.Circle>•</STC.Circle>
      <STC.Text as="span">
        Arts (Drawing)
      </STC.Text>
    </STC.ContainerSocial>

    <STC.ContainerPosition xs="12" lg="4">
      <STC.Span>Known languages</STC.Span>
      <STC.Text as="span"
      >
        Italian
      </STC.Text>
      <STC.Circle>•</STC.Circle>
      <STC.Text as="span">
        English
      </STC.Text>
    </STC.ContainerPosition>
  </STC.Information>
)

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import anime from 'animejs'
import { STC } from './style'

class SvgWrapperTab extends Component {
  initialize = () => {
    anime({
      targets: '#' + this.props.id + ' path',
      d: [
        {
          value:
            'M 60.2019 139.968C 10.0264 141.87 -15.9066 59.2741 10.4905 15.8247C 36.8876 -27.6246 60.2019 30.8118 74.8044 43.5304C 89.4069 56.2491 129.333 5.46655 138.59 26.0864C 147.847 46.7062 110.377 138.065 60.2019 139.968Z'
        },
        {
          value:
            'M 94.7303 124.45C 37.6879 145.918 39.743 147.892 33.2237 112.891C 26.7044 77.8904 -20.0016 75.3768 9.914 28.6733C 39.8296 -18.0302 145.625 -3.39022 139.766 41.766C 133.907 86.9223 151.773 102.983 94.7303 124.45Z'
        },
        {
          value:
            'M 140 70C 140 108.66 108.66 140 70 140C 31.3401 140 0 108.66 0 70C 0 31.3401 31.3401 0 70 0C 108.66 0 140 31.3401 140 70Z'
        },
        {
          value:
            'M 124.947 96.4905C 189.446 158.421 26.9318 145.985 8.50335 110.278C -9.92511 74.5713 3.08441 68.4983 33.2399 26.0748C 63.3955 -16.3488 145.302 -3.12518 139.397 37.8929C 133.491 78.911 60.4474 34.5605 124.947 96.4905Z'
        },
        {
          value:
            'M 120.975 100.634C 174.428 163.517 60.2338 140.583 44.9616 104.327C 29.6894 68.0698 -17.8624 77.2397 7.12832 34.1629C 32.1191 -8.91386 86.1565 -3.77269 129.305 9.32019C 172.454 22.4131 67.5226 37.7501 120.975 100.634Z'
        }
      ],
      easing: 'easeInOutQuad',
      duration: 10000,
      loop: true,
      direction: 'alternate'
    })
  };

  move = index => {
    const currentSVG = this.props.svrRef[index].current
    const xSvg = currentSVG.getBoundingClientRect().x
    const widthSvg = currentSVG.getBoundingClientRect().width
    const xContainer = this.props.tabRef.current.getBoundingClientRect().x
    const ySvg = currentSVG.getBoundingClientRect().y
    const yContainer = this.props.tabRef.current.getBoundingClientRect().y
    const heightSvg = currentSVG.getBoundingClientRect().height
    anime.remove('#' + this.props.id)
    anime({
      targets: '#' + this.props.id,
      translateX: xSvg - xContainer + widthSvg / 3,
      translateY: ySvg - yContainer - heightSvg / 2,
      easing: 'easeOutElastic',
      duration: 2000
    })
  };

  getProperty = (element, property) =>
    +window.getComputedStyle(element)[property].slice(0, -2);

  componentDidMount = () => {
    setTimeout(() => {
      this.initialize()
      if (this.props.move === true) {
        this.move(this.props.transitionSVG || 1)
      }
    }, 200)
  };

  componentWillUpdate (nextProps, nextState) {
    if (
      this.props.move === true &&
      this.props.transitionSVG !== nextProps.transitionSVG
    ) {
      this.move(nextProps.transitionSVG)
    }
  }

  render () {
    return (
      <STC.SvgWrapper style={this.props.noCss && { left: 'auto', top: 'auto' }}>
        <STC.Svg
          id={this.props.id}
          style={{
            width: this.props.width + 'px',
            height: this.props.height + 'px'
          }}
          viewBox={`0 0 ${this.props.viewboxW || 140} ${this.props.viewboxH ||
            140}`}
          alt="Fluido rosa"
        >
          <path d="M 120.975 100.634C 174.428 163.517 60.2338 140.583 44.9616 104.327C 29.6894 68.0698 -17.8624 77.2397 7.12832 34.1629C 32.1191 -8.91386 86.1565 -3.77269 129.305 9.32019C 172.454 22.4131 67.5226 37.7501 120.975 100.634Z" />
        </STC.Svg>
      </STC.SvgWrapper>
    )
  }
}

export default SvgWrapperTab

import { NavLink } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'
import { mediaMaxW, mediaMinW } from 'utils/mq'

const scaleX = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`
export const STC = {
  BaseNavLink: styled(NavLink)`
    letter-spacing: 0.6px;
    font-size: 1.4rem;
    text-transform: uppercase;
    position: relative;
    font-family: "Rubik";
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    color: ${props => props.theme.main};

    transition: all 0.3s;
    &:hover {
      opacity: 1;
    }
    ${mediaMinW.md`
        &.active:before {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 2px;
          background: ${({ theme }) => theme.primary};
          border-radius: 500px;
          animation: ${scaleX} 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        }

        &.active:hover:after {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 2px;
          background: ${({ theme }) => theme.accent};
          border-radius: 500px;
          animation: ${scaleX} 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        }
        &.active:hover {
          color: ;
        }

        &:hover:before {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 2px;
          background: ${({ theme }) => theme.accent};
          border-radius: 500px;
          animation: ${scaleX} 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        }
      `};

    ${mediaMaxW.md`
        font-size: 1.4rem;
        text-align: left;
        text-decoration: none;
        /*opacity: 0.7;*/
        margin-bottom: 3rem;
        color: #fff;

        @media (min-height: 500px) {
          font-size: 1.8rem;
          margin-bottom: 1.2rem;
          color: #fff;
        }
        @media (min-height: 600px) {
          font-size: 2.1rem;
          margin-bottom: 1.5rem;
          color: #fff;
        }
        @media (min-height: 700px) {
          font-size: 2.4rem;
          margin-bottom: 2rem;
          color: #fff;
        }
      `};
  `
}

import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import routes from './routes'
import { GridFluid } from 'styles/flexboxgrid'
import { STC } from './style'

const Routes = ({ location, children }) => (
  <STC.Wrapper>
    <TransitionGroup className="transition-group">
      <CSSTransition
        key={location.key}
        timeout={{ enter: 2000, exit: 2000 }}
        classNames={'fade'}
      >
        <div className="wrapper-absolute">
          <GridFluid as="main" id="mainGrid">
            <Switch location={location}>
              {routes.map(({ exact, path, Component }, i) => (
                <Route
                  location={location}
                  exact={exact}
                  path={path}
                  component={Component}
                  key={i}
                />
              ))}
              {/* <Route path="/404" component={NotFound} /> */}
              <Redirect to="/404" />
            </Switch>

            {children}
          </GridFluid>
        </div>
      </CSSTransition>
    </TransitionGroup>
  </STC.Wrapper>
)

export default withRouter(Routes)

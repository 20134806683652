import React, { Component, Fragment } from 'react'
import CustomNavLink from './components/customNavLink'
import { withRouter } from 'react-router-dom'
import { STC } from './style'
import { FormattedMessage } from 'react-intl'
import track from 'react-tracking'
import { gotoSectionId } from 'utils/gotoSectionId'

@track({})
class NavBar extends Component {
  state = {
    windowWidth: window.innerWidth,
    opened: false,
    open: false
  };
  closeMenu = () => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      this.setState(
        {
          opened: false
        },
        () => {
          if (this.state.opened) {
            document.body.style.overflowY = 'hidden'
          } else {
            document.body.style.overflowY = 'auto'
          }
        }
      )
    }
  };

  isOpen = () => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      this.setState(
        {
          opened: !this.state.opened
        },
        () => {
          if (this.state.opened) {
            document.body.style.overflowY = 'hidden'
          } else {
            document.body.style.overflowY = 'auto'
          }
        }
      )
    }
  };

  showContactCta = () => {
    return this.props.location.pathname === 'contacts'
  };

  resizeWidth = () => {
    window.onresize = () => {
      this.setState({ windowWidth: window.innerWidth })
    }
  };

  onClickGoTo = (sectionName) => (e) => {
    e.preventDefault()
    gotoSectionId(sectionName)
    this.isOpen()
  }

  render () {
    this.resizeWidth()
    return (
      <Fragment>
        <STC.Header opened={this.state.opened ? this.state.opened : undefined}>
          <STC.NavLogo
            opened={this.state.opened ? this.state.opened : undefined}
          />
          <STC.Navigation
            opened={this.state.opened ? this.state.opened : undefined}
          >
            <STC.BackgroundMobile
              opened={this.state.opened ? this.state.opened : undefined}
            />
            {this.state.windowWidth <= 768 && (
              <STC.OpenedMenuLogo
                opened={this.state.opened ? this.state.opened : undefined}
                onClick={this.closeMenu}
              />
            )}
            <STC.Nav opened={this.state.opened ? this.state.opened : undefined}>
              {/* <CustomNavLink
                text={<FormattedMessage id="Nav.hello" />}
                to="/hello"
                onClick={this.isOpen}
              /> */}
              <CustomNavLink
                text={<FormattedMessage id="Nav.aboutme" />}
                to="/"
                onClick={this.onClickGoTo('about-me')}
              />
              <CustomNavLink
                text={<FormattedMessage id="Nav.skills" />}
                to="#skills"
                onClick={this.onClickGoTo('skills')}
              />
              <CustomNavLink
                text={<FormattedMessage id="Nav.certifications" />}
                to="#certifications"
                onClick={this.onClickGoTo('certifications')}
              />
            </STC.Nav>
            <STC.ContactButton

              opened={this.state.opened ? this.state.opened : undefined}
              onClick={() => {
                this.props.tracking.trackEvent({ event: 'contact' })
                this.isOpen()
                window.open('https://www.linkedin.com/in/massimo-fortarezza/', '_blank').focus()
              }}

            ><FormattedMessage id="Nav.contacts" /></STC.ContactButton>
          </STC.Navigation>
        </STC.Header>
        {this.state.windowWidth <= 768 && (
          <STC.HamburgerMenu
            method={this.isOpen}
            opened={this.state.opened ? this.state.opened : undefined}
          />
        )}
      </Fragment>
    )
  }
}

export default withRouter(NavBar)

import styled, { keyframes } from 'styled-components'
import Logo from 'assets/svg/Logo'
import Hamburger from './components/hamburger'
import {BaseButton} from 'components/button'

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const STC = {
  Header: styled.header`
    opacity: 0;
    animation: ${FadeIn} 1000ms cubic-bezier(0.86, 0, 0.07, 1);
    animation-delay: 1s;
    animation-fill-mode: forwards;
    display: flex;
    align-items: center;
    height: 12rem;
    max-width: 120rem;
    margin: 3rem auto;
    padding: 0 4rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: all 0.4s;
    @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
      z-index: ${({ opened }) => (opened ? '10' : '5')};
    }
  `,

  Navigation: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
      visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
    }
  `,

  Nav: styled.nav`
    z-index: 10;
    display: flex;
    justify-content: space-around;
    width: 35rem;
    @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
      visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
      opacity: ${({ opened }) => (opened ? 1 : 0)};
      flex-direction: column;
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      /*top: 0px;*/
      /*bottom: 20%;*/
      right: 8rem;
      /*height: 40rem;*/
      width: auto;
      justify-content: center;
      align-items: center;
      /*align-items: flex-end;*/
      transition: all 0.4s;
      transition-delay: 0.1s;
    }
  `,

  NavLogo: styled(Logo)`
    margin-top: 1rem;
    margin-right: auto;
    height: 10rem;
    width: 10rem;
    @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
      padding-top: 2rem;
      width: 10rem;
      height: 10rem;
      margin: ${({ opened }) => (!opened ? ' 0 auto' : '0 auto')};
      transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
      &>div {
        margin-top: 2rem;
      }
    }
  `,
  OpenedMenuLogo: styled(Logo)`
    align-self: flex-start;
    visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
    opacity: ${({ opened }) => (opened ? '1' : '0')};
    padding-top: ${({ opened }) => (!opened ? ' 0rem' : '2rem')};
    width: 10rem;
    height: 10rem;
    &>div {
      margin-top: 2rem;
    }
    margin: ${({ opened }) => (!opened ? ' 0 auto' : '0 auto')};
    transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    z-index: 11;
  `,

  HamburgerMenu: styled(Hamburger)`
    position: fixed;
    bottom: 0px;
    right: 0px;
    margin: 5rem 3rem;
    padding: 1rem;
    z-index: 10;
    background: ${({ opened }) => (!opened ? '#fff9' : 'transparent')};
    border-radius: 100%;
  `,

  BackgroundMobile: styled.div`
    @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: -101rem;
      right: -101rem;
      height: 200rem;
      width: 200rem;
      border-radius: 50%;
      /* background-image: radial-gradient(#fdb664, #fa688a); */
      background: #222831;
      transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
      transform: scale(${({ opened }) => (opened ? 1.5 : 0)});
      backface-visibility: hidden;
    }
  `,

  ContactButton: styled(BaseButton)`
    margin-left: 2rem;
    background: ${({ theme }) => theme.primary};
    color: background: ${({ theme }) => theme.dark};
    @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      padding: 1.5rem 7rem;
      left: 50%;
      margin: 0;
      /*background-image: none;*/
      color: ${({ theme }) => theme.main};
      visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
      opacity: ${({ opened }) => (opened ? '1' : '0')};
      z-index: 10;
line-height: 2.2rem;
      @media (min-height: 500px) {
        top: 74%;
        font-size: 1.8rem;
      }
      @media (min-height: 600px) {
        top: 73%;
        font-size: 2.1rem;
      }
      @media (min-height: 700px) {
        top: 72%;
        font-size: 2.4rem;
      }
    }
  `
}

import styled, { css } from 'styled-components'
const gridColumn = 12
/**
 * [breakpoints description]
 * @type {Object}
 * xs: <576px | auto,
   sm: ≥ 576px | 540px,
   md: ≥ 768px | 720px,
   lg ≥ 992px | 960px
   xl: ≥ 1200px | 1140px
 */
export const breakpoints = {
  xs: {
    width: '100'
  },
  sm: {
    media: '576px',
    width: '540px'
  },
  md: {
    media: '768px',
    width: '720px'
  },
  lg: {
    media: '992px',
    width: '960px'
  },
  xl: {
    media: '1200px',
    width: '1140px'
  }
}
function getWidthAndFlex (prefix) {
  if (!prefix) return
  let width = (prefix / gridColumn) * 100
  return `
    flex-basis: ${width}%;
    max-width: ${width}%;
    `
}
function getOffset (prefix) {
  if (!prefix) return
  let width = (prefix / gridColumn) * 100
  return `margin-left:${width}%;`
}
export const Grid = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 35px;
  padding-left: 35px;
  width: 80%;
  @media only screen and (min-width: ${breakpoints.sm.media}) {
    width: ${breakpoints.sm.width};
  }
  @media only screen and (min-width: ${breakpoints.md.media}) {
    width: ${breakpoints.md.width};
  }
  @media only screen and (min-width: ${breakpoints.lg.media}) {
    width: ${breakpoints.lg.width};
  }
  @media only screen and (min-width: ${breakpoints.xl.media}) {
    width: ${breakpoints.xl.width};
  }
`
export const GridFluid = styled.div`
  margin-right: auto;
  margin-left: auto;
  /* padding-right: 15px;
  padding-left: 15px; */
`
export const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -9px;
  margin-left: -9px;

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
    `};

  ${({ mrg_t }) =>
    mrg_t &&
    css`
      margin-top: ${mrg_t}rem;
    `};

  ${({ mrg_b }) =>
    mrg_b &&
    css`
      margin-bottom: ${mrg_b}rem;
    `};

  ${({ mrg_l }) =>
    mrg_l &&
    css`
      margin-left: ${mrg_l}rem;
    `};

  ${({ mrg_r }) =>
    mrg_r &&
    css`
      margin-right: ${mrg_r}rem;
    `};

  ${({ pd_t }) =>
    pd_t &&
    css`
      padding-top: ${pd_t}rem;
    `};

  ${({ pd_b }) =>
    pd_b &&
    css`
      padding-bottom: ${pd_b}rem;
    `};

  ${({ pd_l }) =>
    pd_l &&
    css`
      padding-left: ${pd_l}rem;
    `};

  ${({ pd_r }) =>
    pd_r &&
    css`
      padding-right: ${pd_r}rem;
    `};

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `};

  ${({ start }) =>
    start &&
    css`
      justify-content: flex-start;
    `};

  ${({ end }) =>
    end &&
    css`
      justify-content: flex-end;
    `};

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `};

  ${({ center_txt }) =>
    center_txt &&
    css`
      justify-content: center;
      text-align: center;
    `};

  ${({ start_txt }) =>
    start_txt &&
    css`
      justify-content: flex-start;
      text-align: left;
    `};

  ${({ end_txt }) =>
    end_txt &&
    css`
      justify-content: flex-end;
      text-align: right;
    `};

  ${({ top }) =>
    top &&
    css`
      align-items: flex-start;
    `};
  ${({ middle }) =>
    middle &&
    css`
      align-items: center;
    `};
  ${({ bottom }) =>
    bottom &&
    css`
      align-items: flex-end;
    `};
  ${({ around }) =>
    around &&
    css`
      justify-content: space-around;
    `};
  ${({ between }) =>
    between &&
    css`
      justify-content: space-between;
    `};
  ${({ first }) =>
    first &&
    css`
      order: -1;
    `};
  ${({ last }) =>
    last &&
    css`
      order: 1;
    `};
`
export const Col = styled.div`
  position: relative;
  box-sizing: border-box;
  flex: 1 0 100%;
  padding-right: 9px;
  padding-left: 9px;

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: column-reverse;
    `};

  ${({ mrg_t }) =>
    mrg_t &&
    css`
      margin-top: ${mrg_t}rem;
    `};

  ${({ mrg_b }) =>
    mrg_b &&
    css`
      margin-bottom: ${mrg_b}rem;
    `};

  ${({ xs }) => xs && getWidthAndFlex(xs)};
  ${({ xs_off }) => xs_off && getOffset(xs_off)};
  @media only screen and (min-width: ${breakpoints.sm.media}) {
    ${({ sm }) => sm && getWidthAndFlex(sm)};
    ${({ sm_off }) => sm_off && getOffset(sm_off)};
  }
  @media only screen and (min-width: ${breakpoints.md.media}) {
    ${({ md }) => md && getWidthAndFlex(md)};
    ${({ md_off }) => md_off && getOffset(md_off)};
  }
  @media only screen and (min-width: ${breakpoints.lg.media}) {
    ${({ lg }) => lg && getWidthAndFlex(lg)};
    ${({ lg_off }) => lg_off && getOffset(lg_off)};
  }
  @media only screen and (min-width: ${breakpoints.xl.media}) {
    ${({ xl }) => xl && getWidthAndFlex(xl)};
    ${({ xl_off }) => xl_off && getOffset(xl_off)};
  }
`

import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const Base = css`
  letter-spacing: 0.6px;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  color: ${({ theme }) => theme.main};
  /*opacity: 0.8;*/
  /*font-size: 1.6rem;*/
  padding: 10px 2rem;
  border-radius: 500px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
`

export const BaseLink = styled(Link)`
  ${Base};
`

export const BaseButton = styled.button`
  ${Base};
`

import styled from 'styled-components'
import { Watch } from 'scrollmonitor-react'
import { Heading } from 'styles/typography'
import { Col, Row } from 'styles/flexboxgrid'
import {BaseButton} from 'components/button'
import { Keyframes, animated, config, Spring } from 'react-spring'
import { sleep } from 'utils/sleep'

export const STC = {
  Wrapper: styled.div``,

  Blockquote: styled(Heading.h2)`
  color: ${({ theme }) => theme.main};
  `,

  Card: styled(Col)`
    border-radius: 4px;
    background: #fff;
    box-shadow: ${({ theme }) => '0 8px 18px ' + theme.accent + '55'};
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
    &:hover {
      box-shadow:${({ theme }) => '0 16px 30px ' + theme.accent + '99'};
    }
    margin-top: 6rem;
    @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
    display: none;
    }

  `,

  Section: Watch(styled(Row)`
    @media (min-width: ${({ theme }) => theme.breakpoints.md.media}) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  `),

  ColoredButton: styled(BaseButton)`
    background: ${({ theme }) => theme.primary};
    color: #fff;
  `,

  ContentTrail: Keyframes.Trail({
    inView: async next => {
      await sleep(500)
      next({ from: { opacity: 0 }, to: { opacity: 1 } })
    },
    notInView: async next => {
      await next({ from: { opacity: 1 }, to: { opacity: 0 } })
    }
  }),

  Content: Keyframes.Spring.to({
    inView: async next => {
      await sleep(200)
      next({ from: { x: -10, opacity: 0 }, to: { x: 0, opacity: 1 } })
      await sleep(200)
      await next({
        from: { y: -10, opacityInside: 0 },
        to: { y: 0, opacityInside: 1 }
      })
    },
    notInView: async next => {
      await next({
        from: { y: 0, opacityInside: 1, opacity: 1, x: 1 },
        to: { y: -10, opacityInside: 0 }
      })
      await next({ from: { x: 0, opacity: 1 }, to: { x: -10, opacity: 0 } })
    }
  })
}

import { Link } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

const scaleX = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`

export const STC = {
  BaseLink: styled(Link)`
    letter-spacing: 0.5px;
    font-size: 1.3rem;
    text-transform: uppercase;
    position: relative;
    font-family: "Rubik";
    font-weight: 400;
    font-style: normal;
    /*text-transform: uppercase;
      font-size: 1.4rem;*/
    text-decoration: none;
    color: ${props => props.theme.dark};
    transition: all 0.3s;
    &:hover {
      opacity: 1;
    }

    &.active:before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.primary};
      border-radius: 500px;
      animation: ${scaleX} 0.3s cubic-bezier(0.86, 0, 0.07, 1);
    }

    &.active:hover:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.primary};
      border-radius: 500px;
      animation: ${scaleX} 0.3s cubic-bezier(0.86, 0, 0.07, 1);
    }
    &.active:hover {
      color: ;
    }

    &:hover:before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.primary};
      border-radius: 500px;
      animation: ${scaleX} 0.3s cubic-bezier(0.86, 0, 0.07, 1);
    }
  `
}

import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends PureComponent {
  componentDidMount () {
    setTimeout(function () {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }, 100)
  }
  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location && !window.location.href.includes('#')) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  render () {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)

import ArrowRight from 'assets/svg/ArrowRight'
import Line from 'components/line'
import React, { Fragment, PureComponent } from 'react'
import { animated, config } from 'react-spring'
import track from 'react-tracking'
import { Watch } from 'scrollmonitor-react'
import { Col } from 'styles/flexboxgrid'
import { Heading, Paragraph, Subtitle } from 'styles/typography'
import { STC } from './style'

const Image = Watch(({ image }) => (
  <Col xs="9" sm="8" md="5" lg="4">
    {image}
  </Col>
))

@track({})
class Card extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isFullyInViewport: undefined
    }
  }

  // @track(props => {
  //   return { event: props.link }
  // })
  click = (e) => {
    e.preventDefault()
    this.props.tracking.trackEvent({ event: this.props.link })
    window.open(this.props.link, '_blank').focus()
  };
  items = [
    props => (
      <Paragraph
        style={{ marginBottom: props.cta && '3rem', marginTop: '6rem' }}
        dangerouslySetInnerHTML={{ __html: props.text }}
      />
    ),
    props => (
      <Fragment>
        {props.cta ? (
          <STC.Pill to={props.link} onClick={this.click}>
            {props.cta + '  '}
            <ArrowRight />
          </STC.Pill>
        ) : null}
      </Fragment>
    )
  ];

  render () {
    const {
      heading,
      subtitle,
      text,
      image,
      imageForeground,
      cta,
      link,
      index,
      indexWatcher
    } = this.props
    const state =
      this.state.isFullyInViewport === undefined
        ? 'notInView'
        : this.state.isFullyInViewport === true
          ? 'inView'
          : 'noMoreInView'

    return (
      <STC.Content native config={STC.fast} state={state}>
        {({ x, y, opacityInside, ...props }) => (
          <STC.SpringWrapper
            style={{
              transform: x.interpolate(x => `translateX(${x}%)`),
              ...props
            }}
          >
            <STC.CardWrapper center middle>
              <Image
                {...props}
                image={this.props.image}
                innerRef={(el, watcher, props) => {
                  this.props.registerWatcher(this.props.index + 1, watcher)
                }}
                fullyEnterViewport={(watcher, childProps) => {
                  this.setState({ isFullyInViewport: true })
                  this.props.getItemInViewport(index + 1, watcher)
                }}
                partiallyExitViewport={(watcher, childProps) => {
                  this.props.removeCurrentItemFromViewport(
                    this.props.index + 1
                  )
                }}
                exitViewport={(watcher, childProps) => {
                  this.setState({ isFullyInViewport: false })
                }}
              />
              <Fragment>
                <Col sm="12" md="6" md_off="1" lg="6" lg_off="1">
                  <STC.SpringWrapper
                    style={{
                      opacity: opacityInside
                    }}
                  >
                    <Heading.h2>{heading}</Heading.h2>
                    <Subtitle.h2 style={{ position: 'relative' }}>
                      {subtitle}
                      <Line bottom={'-3rem'} width={'6rem'} left={'0'} />
                    </Subtitle.h2>

                    {/* <Rect native config={fast} state={state}>
                       {({y, ...props}) => <Box
                        style={{ transform: y.interpolate(y => `scaleX(${y})`), ...props }}>
                       </Box>}
                    </Rect> */}
                    <STC.ContentDescription
                      native
                      keys={this.items.map((_, i) => i)}
                      config={config.slow}
                      state={state}
                    >
                      {this.items.map((Item, i) => ({ x, ...props }) => (
                        <animated.div
                          style={{
                            transform: x.interpolate(x => `translateX(${x}%)`),
                            ...props
                          }}
                        >
                          <Item {...this.props} />
                        </animated.div>
                      ))}
                    </STC.ContentDescription>
                  </STC.SpringWrapper>
                </Col>
              </Fragment>
            </STC.CardWrapper>
          </STC.SpringWrapper>
        )}
      </STC.Content>
    )
  }
}

export default Card

import React from 'react'
import { STC } from './style'

const Hamburger = ({ className, method, opened }) => (
  <div className={className}>
    <STC.HamburgerContainer onClick={method} opened={opened}>
      <STC.HamburgerSpan />
      <STC.HamburgerSpan />
      <STC.HamburgerSpan opened={opened} />
    </STC.HamburgerContainer>
  </div>
)

export default Hamburger


export const gotoSectionId = (sectionName) => {
  const el = document.getElementById(sectionName)
  function findPos (obj) {
    var curtop = 0
    if (obj && obj.offsetParent) {
      do {
        curtop += obj.offsetTop
        // eslint-disable-next-line no-cond-assign
      } while (obj = obj.offsetParent)
      return [curtop]
    }
  }
  // window.history.pushState(sectionName, 'Massimo Fortarezza | Solution Architect and Full Stack Engineer', '#' + sectionName)
  window.scroll(0, findPos(el))
}

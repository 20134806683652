import React from 'react'
import { STC } from './style'
import NavLink from './components/link'
import { gotoSectionId } from 'utils/gotoSectionId'

const data = [
  { url: '', to: '#', text: 'Hello!' },
  { url: '', to: '#about-me', text: 'About me' },
  { url: 'skills', to: '#skills', text: 'Skills' },
  { url: 'certifications', to: '#certifications', text: 'Certifications' }

]

export default ({ navigation }) => {
  const onClickGoTo = (sectionName) => (e) => {
    e.preventDefault()
    gotoSectionId(sectionName)
  }
  return (
    <STC.NavFooter bottom column>
      {data.map((item, index) => (
        <STC.Wrapper key={index}>
          <NavLink to={item.to} text={item.text} onClick={onClickGoTo(item.url)} />
        </STC.Wrapper>
      ))}
    </STC.NavFooter>
  )
}

import React from 'react'
import { Row } from 'styles/flexboxgrid'
import Card from './components/card'
import { data } from './components/data'

export default props => (
  <Row center id="skills">
    {data.map((card, index) => (
      <Card {...card} index={index} key={index} {...props} />
    ))}
  </Row>
)

import styled from 'styled-components'

export const STC = {
  LineStyle: styled.span`
    display: block;
    position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
    bottom: ${({ bottom }) => bottom};
    left: ${({ left }) => left};
    width: ${({ width }) => width};
    height: 2px;
    border-radius: 500px;
    line-height: 1em;
    /*
    background-color: #3071f3;
     */
    background-color: ${({ theme }) => theme.subtitle};
  `
}

import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import anime from 'animejs'
import { theme } from '../../styles/global'
import { Heading } from 'styles/typography'

const Background = styled.path`
  fill: ${({ theme }) => theme.primary};
  opacity: 0.496;
`
const Line = styled.path`
  fill: ${({ theme }) => theme.primary};
  /* stroke: ${({ theme }) => theme.primary}; */
`
const Text = styled(Heading.h4)`
  text-decoration: none;
  font-style: normal;
  font-size: 1.6rem;
  & hover {
    color:${({ theme }) => theme.dark};
  }
  color: ${({ theme }) => theme.dark};
  margin: 0;
  padding: 0;
  margin-top: 1rem;
`

class LogoSvgComponent extends React.PureComponent {
  constructor (props) {
    super(props)
    this.ownRef = React.createRef()
  }

  componentDidMount () {
    setTimeout(() => {
      anime.remove(this.ownRef.current.children[1])
      anime({
        targets: this.ownRef.current.children[1],
        easing: 'easeInOutQuad',
        d: [
          {
            value:
              'M17.4966272,71.4196036 C-3.05043007,89.8056802 -13.3442845,154.156949 31.8795673,154.156949 C77.103419,154.156949 70.8986503,181.736064 96.6027977,184.80041 C122.306945,187.864756 144.156342,155.184593 159.357576,118.361873 C174.558811,81.5391519 208.537243,76.0783331 159.357576,57.1193217 C110.17791,38.1603104 106.876326,-10.2962927 69.8916232,1.96109171 C32.9069201,14.2184762 38.0436845,53.0335269 17.4966272,71.4196036 Z',
            duration: 2000
          },
          {
            value:
              'M4,54 C-4,76 3,133 23,157 C43,181 58.2055953,183.600819 96.6027977,185.80041 C135,188 165,170 175,147 C185,124 196,92 175,54 C154,16 122.286619,-7.07781658 69.8916232,2.96109171 C17.4966272,13 12,32 4,54 Z',
            duration: 2000
          },
          {
            value:
              'M13,66 C-14,85 6,140 29,157 C52,174 71.8972652,160 87,157 C102.102735,154 137,193 158,164 C179,135 142,105 179,54 C216,3 119.102735,2 81,9 C42.8972652,16 40,47 13,66 Z',
            duration: 2000
          },
          {
            value:
              'M9,116 C40,136 27,154 50,171 C73,188 90,159 140,159 C190,159 165,116.015305 186,87.0153054 C207,58.0153054 132,54 124,25 C116,-4 45,-10 41,25 C37,60 -22,96 9,116 Z',
            duration: 2000
          },
          {
            value:
              'M13,69 C-14,88 6,140 29,157 C52,174 70.6027977,179.80041 109,182 C147.397202,184.19959 133,154 137,135 C141,116 153,81 172,51 C191,21 125.20547,9 87.1027348,16 C49,23 40,50 13,69 Z',
            duration: 2000
          }
        ],
        loop: true,
        direction: 'alternate'
      })
    }, 1000)
  }

  render () {
    const props = this.props
    return (
      <Link to="/" {...props} style={{position: 'relative'}}>
        <svg
          viewBox="0 0 190 200"
          {...props}
          ref={this.ownRef}
          alt="Logo Max Fortarezza"
        >
          <Background d="M18 76.042c0 85.7 2.637 93.756 42.523 108.881 39.886 15.126 88.978-28.468 104.89-66.382 15.91-37.915 16.75-81.283-34.728-100.804C79.208-1.783 18-9.659 18 76.042z" />
          <Line
            d="M17.4966272,71.4196036 C-3.05043007,89.8056802 -13.3442845,154.156949 31.8795673,154.156949 C77.103419,154.156949 70.8986503,181.736064 96.6027977,184.80041 C122.306945,187.864756 144.156342,155.184593 159.357576,118.361873 C174.558811,81.5391519 208.537243,76.0783331 159.357576,57.1193217 C110.17791,38.1603104 106.876326,-10.2962927 69.8916232,1.96109171 C32.9069201,14.2184762 38.0436845,53.0335269 17.4966272,71.4196036 Z"
            strokeWidth={5}
          />

          <Text />
        </svg>
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Text>MAX.F</Text>
        </div>
      </Link>
    )
  }
}

export default LogoSvgComponent

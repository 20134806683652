import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import NavBar from 'components/navbar'
import Lang from 'components/lang'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from 'styles/global'
import Routes from 'routes'
import Footer from 'components/footer'
import ScrollToTop from 'components/scrollToTop'
import Mockup from 'assets/images/Mockup4.jpg'
import Logo from 'assets/images/logo.png'
import track from 'react-tracking'
// START LANGUAGES
import { IntlProvider, addLocaleData } from 'react-intl'
import it from 'react-intl/locale-data/it'
import en from 'react-intl/locale-data/en'
import localeData from './locales/data.json'
// END LANGUAGES

addLocaleData([...it, ...en])

@track({})
class App extends React.Component {
  state = {
    locale: 'en',
    messages: localeData['en']
  }

  changeLanguageHandler = lang => {
    this.setState({ locale: lang, messages: localeData[lang] })
  }

  render () {
    return (
      <IntlProvider locale={this.state.locale} messages={this.state.messages}>
        <ThemeProvider theme={theme}>
          <Fragment>
            <Helmet>
              <meta
                name="og:description"
                content="Hello I am Max, and I am a full stack engineer & solution architect"
              />
              <meta name="og:image" content={Mockup} />
              <script type="application/ld+json">
                {`{
                {
                  "@context": "http://schema.org",
                  "givenName": "Massimo",
                  "familyName": "Fortarezza",
                  "homeLocation": "Lugano",
                  "birthPlace": "Milan",
                  "nationality": "Italian",
                  "gender": "Male",
                  "jobTitle": "Full Stack Software Engineer and Solution Architect",
                  "knowsAbout": "IT, web development, microservices, event sourcing, cloud services, Amazon Web Services, NodeJs, ReactJs, mobile development, React Native, and Scrum",
                  "knowsLanguage": "English, Italian",
                  "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Milan",
                    "postalCode": "20098",
                    "streetAddress": "20098 Via Milano, 18, San Giuliano Milanese Mi"
                  },
                  "email": "max.fortarezza@gmail.com",
                  "image": ${Mockup},
                  "contactPoint": [{
                    "@type": "ContactPoint",
                    "telephone": "+39 3475068811",
                    "contactType": "customer service"
                  }],
                  "url": "https://massimofortarezza.com",
                  "colleague": "Davide Valenzano",
                  "alumniOf": "https://www.ied.it/",
                  founder: {
                    "@type": "Organization",
                    "brand": {
                      "@type" : "Brand",
                      "name" : "Fluent.studio",
                      "logo": ${Logo}
                    },
                    "description": "Software consulting Services",
                  }
                }
              }`}
              </script>
            </Helmet>
            <ParallaxProvider>
              <GlobalStyle />
              <ScrollToTop>
                {/* <Lang
                  languages={[
                    {
                      lang: 'it',
                      onClick: this.changeLanguageHandler
                    },
                    {
                      lang: 'en',
                      onClick: this.changeLanguageHandler
                    }
                  ]}
                /> */}
                <NavBar />
                <Routes>
                  <Footer />
                </Routes>
              </ScrollToTop>
            </ParallaxProvider>
          </Fragment>
        </ThemeProvider>
      </IntlProvider>
    )
  }
}
export default App

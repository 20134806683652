import React from 'react'
import { NavLink } from 'react-router-dom'
import { STC } from './style'
import { Col } from 'styles/flexboxgrid'
import SvgBubble from 'components/svgBubble'

export default ({ mail, to }) => (
  <STC.Mail>
    <SvgBubble
      id="svgFooter"
      width={100}
      height={100}
      viewboxW={200}
      viewboxH={200}
      move={false}
      noCss={true}
    />
    <Col xs="12">
      <STC.H1 as={NavLink} to={to}>
        {mail}
      </STC.H1>
    </Col>
  </STC.Mail>
)

import { css } from 'styled-components'

export default css`
  .optanon-alert-box-wrapper {
    bottom: -5px;
    display: block;
  }

  /**
* Reset button styles.
* It takes a bit of work to achieve a neutral look.
*/
  button {
    outline: none;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &:focus {
      outline: 0;
      outline: none;
    }
  }

  html,
  body {
    height: 100%;
  }

  .slick-prev {
    left: -25px;
    background-color: #fff9;
  }

  .swal2-container.swal2-fade.swal2-in,
  .swal2-modal.swal2-show {
    display: none;
  }
`

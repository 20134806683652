import React, { Component } from 'react'
import SvgMorph from './components/SvgMorph'
import anime from 'animejs'
import { sleep } from 'utils/sleep'
import { STC } from './style'

class SvgMorphComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      initialized: false
    }
    this.DOM = {}
    this.contentElemsTotal = 0
    this.step = 0
    this.watcher = []
  }

  componentDidMount () {
    this.svgRef = React.createRef()
    this.svgParentRef = React.createRef()
  }

  componentWillUnmount () {
    this.setState({ initialized: false })
  }

  componentWillReceiveProps (nextProps) {
    if (this.state.initialized === false) {
      this.setState({ initialized: true }, () => {
        this.DOM = {
          svg: this.svgParentRef.current,
          shapeEl: this.svgRef.current.lastChild
        }
        const initializeCheck = async () => {
          if (nextProps.itemsInViewport.current.item.watchItem !== undefined) {
            this.initializeSvgMorph(
              nextProps.itemsInViewport.current.index,
              nextProps.itemsInViewport.current.item
            )
          } else {
            await sleep(1000)
            initializeCheck()
          }
        }
        initializeCheck()
      })
    } else if (
      this.props.itemsInViewport.current.index !==
      nextProps.itemsInViewport.current.index
    ) {
      this.coreSvgMorph(
        nextProps.itemsInViewport.current.index,
        nextProps.itemsInViewport.current.item
      )
    }
  }

  // helper per prendere il getComputedStyle di un elemento
  getProperty = (element, property) =>
    element ? +window.getComputedStyle(element)[property].slice(0, -2) : 0;

  // inizializza il movimento dopo che ha finito la transizion di movimento
  initSvgMorphLoop = (index = 0) => {
    anime.remove(this.DOM.shapeEl)
    anime({
      targets: this.DOM.shapeEl,
      easing: 'easeInOutQuad',
      d: [
        { value: this.props.shapes[index].pathAlt, duration: 3000 },
        { value: this.props.shapes[index].path, duration: 3000 }
      ],
      loop: true,
      direction: 'alternate'
    })
  };

  // inizializza la transizione, da quello che c'è scritto nellhtml a quello che viene passato in props.shapes[0]
  initializeSvgMorph = (index, watcher) => {
    const currentItem = watcher.watchItem
    // sposta l svg prima che entri nella viewport, per far sembrare che arrivi dall'alto
    anime({
      targets: this.DOM.svg,
      duration: 0,
      left: currentItem.getBoundingClientRect().x + 'px'
    })

    setTimeout(
      function () {
        anime.remove(this.DOM.shapeEl)
        anime({
          targets: this.DOM.shapeEl,
          duration: this.props.shapes[0].animation.path.duration,
          easing: this.props.shapes[0].animation.path.easing,
          elasticity: this.props.shapes[0].animation.path.elasticity || 0,
          d: this.generateSmoothTransition(this.props.shapes[0]),
          complete: function () {
            this.initSvgMorphLoop(0)
          }.bind(this)
        })
        const scaleX = this.getValue(this.props.shapes[0].scaleX) || 0
        const scaleY = this.getValue(this.props.shapes[0].scaleY) || 0
        const translateX = this.getValue(this.props.shapes[0].tx) || 0
        const translateY = this.getValue(this.props.shapes[0].ty) || 0

        anime.remove(this.DOM.svg)
        anime({
          targets: this.DOM.svg,
          duration: this.props.shapes[0].animation.svg.duration,
          easing: this.props.shapes[0].animation.svg.easing,
          elasticity: this.props.shapes[0].animation.svg.elasticity || 0,
          width: this.getProperty(currentItem, 'width'),
          height: this.getProperty(currentItem, 'width'),
          left: currentItem.getBoundingClientRect().x + 'px',
          top: currentItem.offsetTop + 'px',
          scaleX: scaleX,
          scaleY: scaleY,
          translateX: translateX + '%',
          translateY: translateY + '%',
          rotate: this.props.shapes[0].rotate + 'deg'
        })
      }.bind(this),
      200
    )
  };

  // genera l'oggetto della transizione utilizzando la proprietà pathTransition = [{values: "",[ duration: ""]}]'
  generateSmoothTransition = shape => {
    if (shape.pathTransition && shape.pathTransition.length > 0) {
      shape.pathTransition.push({ value: shape.path, duration: 600 })
      return shape.pathTransition
    }
    return [{ value: shape.path }]
  };

  getValue = item => {
    const calculateViewport = () =>
      window.matchMedia('(min-width: 1200px)').matches
        ? 'xl'
        : window.matchMedia('(min-width: 992px)').matches
          ? 'lg'
          : window.matchMedia('(min-width: 768px)').matches
            ? 'md'
            : window.matchMedia('(min-width: 576px)').matches
              ? 'sm'
              : 'xs'

    const media = ['xs', 'sm', 'md', 'lg', 'xl']
    const currentMediaIndex = media.indexOf(calculateViewport())
    const getItemWithFallback = (i, index) => {
      const viewport = media[index]
      if (i[viewport] !== undefined) {
        return i[viewport]
      } else if (index <= 0) {
        return i[media[0]]
      } else {
        index -= 1
        return getItemWithFallback(i, index)
      }
    }
    return !isNaN(item) ? item : getItemWithFallback(item, currentMediaIndex)
  };

  // la funziona vera e propria che muove l'oggetto da un posto all'altro, utilizza animejs
  coreSvgMorph = (index, watcher) => {
    if (this.state.initialized === true) {
      watcher.recalculateLocation()
      const currentItem = watcher.watchItem
      if (currentItem && currentItem.nodeType === 1) {
        if (this.props.shapes[index]) {
          anime.remove(this.DOM.shapeEl)
          anime({
            targets: this.DOM.shapeEl,
            duration: this.props.shapes[index].animation.path.duration,
            easing: this.props.shapes[index].animation.path.easing,
            elasticity: this.props.shapes[index].animation.path.elasticity || 0,
            d: this.generateSmoothTransition(this.props.shapes[index]),
            complete: function () {
              this.initSvgMorphLoop(index)
            }.bind(this)
          })

          const scaleX = this.getValue(this.props.shapes[index].scaleX) || 0
          const scaleY = this.getValue(this.props.shapes[index].scaleY) || 0
          const translateX = this.getValue(this.props.shapes[index].tx) || 0
          const translateY = this.getValue(this.props.shapes[index].ty) || 0

          anime.remove(this.DOM.svg)
          anime({
            targets: this.DOM.svg,
            duration: this.props.shapes[index].animation.svg.duration,
            easing: this.props.shapes[index].animation.svg.easing,
            elasticity: this.props.shapes[index].animation.svg.elasticity || 0,
            width: this.getProperty(currentItem, 'width'),
            height: this.getProperty(currentItem, 'width'),
            left: currentItem.getBoundingClientRect().x + 'px',
            top: watcher.top + 'px',
            scaleX: scaleX,
            scaleY: scaleY,
            translateX: translateX + '%',
            translateY: translateY + '%',
            rotate: this.props.shapes[index].rotate + 'deg'
          })
        }
      }
    }
  };

  render () {
    return (
      <STC.SvgWrapper ref={this.svgParentRef}>
        <SvgMorph ref={this.svgRef} />
      </STC.SvgWrapper>
    )
  }
}

function mapStateToProps (state, props) {
  return {
    morphData: state.morph['Home'],
    footer: state.morph['footer']
  }
}

export default SvgMorphComponent

import React from 'react'
import App from 'assets/svg/home/App'
import Web from 'assets/svg/home/Web'
import Team from 'assets/svg/home/Team'
import Chat from 'assets/svg/home/Chat'

export const data = [
  {
    image: <Web />,
    heading: 'Focused on Typescript ecosystem',
    subtitle: 'Typescript  •  NodeJS  •  ReactJs  •  React Native •  DenoJs',
    text:
			'Javascript and of course its superset Typescript are the leading languages nowadays. The npm packages world allow developers to work without reinventing the wheel for each new task and to work in every ecosystyem. That is why It has been my choice in 2015, when I had to choose with which programming language getting proficent. <strong>During my carrer I had chances to work with AngularJs, Cordova, React Native, NodeJs, React, along with popular libriaries like Lerna, ExpressJs and so on.</strong> And now the Deno runtime is the next one to master!'
  },
  {
    image: <Team />,
    heading: 'In love with the Big Picture of the projects',
    subtitle: 'Cloud architecture  •  Architecture  •  Project planning  •  UX design •  Analysis  ',
    text:
			'I have never been the one satisfied by doing the simplest task. I love being challenged, I love understanding why and what I am going to do. <strong>My aim is to have enough information to design the architecture of the software, and the cloud/hybrid architecture to build a resilient, secure and cost efficient application.</strong> So, I am always been the first in the list when there is a need to analyze the specifications and plan what needs to be done, when and how.',
    cta: 'AWS Solution Architect certification',
    link: 'https://www.credly.com/badges/e51d628a-352c-4bea-84b7-b0f9fdd4aba9/linked_in_profile'
  },
  {
    image: <App />,
    // imageForeground: <AppForeground />,
    heading: 'Experienced in leading roles',
    subtitle: 'Documentation  •  Best practices  •  Strive for completeness  •  TDD',
    text:
			'<strong>I want things to be done well.</strong> As team leader my goal, of course, is to lead, monitor, and supervise a group of developers and people to achieve goals that contribute to the growth of the organization. <strong>Team leaders must motivate and inspire the team</strong> by creating an environment that promotes positive communication, while <strong>being an example</strong> of reliability, kindness and trasparency.'
  },

  {
    image: <Chat />,
    heading: 'Used to Remote working',
    subtitle: 'Communication  •  Avaliability  •  Trust',
    text:
			'I have always been a supporter of the remote working. I find commuting to work quite frustrating, since time is important and relationships can be easily built with a good communication. Must be noted also that talent retantion is much easier if its granted a nice life balance. On the other hand <strong>the relationship between the company and the remote worker is based on trust and responsibility which can be proved through communication, availability and the achievement of daily goals.</strong> '
  }
]

import React from 'react'
import { STC } from './style'
import { Col } from 'styles/flexboxgrid'

export default ({ title, subtitle }) => (
  <STC.Head center>
    <Col xs="12">
      <STC.Title>
        {title} <STC.CoffeIcon />
      </STC.Title>
      <STC.Sub>{subtitle} </STC.Sub>
    </Col>
  </STC.Head>
)

import { BaseButton } from 'components/button'
import { Parallax } from 'react-scroll-parallax'
import { animated, config, Keyframes } from 'react-spring'
import { Watch } from 'scrollmonitor-react'
import styled from 'styled-components'
import { Col, Row } from 'styles/flexboxgrid'
import { Heading, Subtitle } from 'styles/typography'

export const STC = {
  Action: styled(BaseButton)`
    background: ${({ theme }) => theme.dark};
    color: ${({ theme }) => theme.main};
  `,

  DivColored: styled.div``,

  CaptionWrapper: styled.div`
    padding: 2rem 0;
    margin-top: 20rem;
    margin-bottom: 20rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  `,

  Caption: styled(Col)``,

  CaptionTitle: styled(Heading.h3)`
    position: relative;
    white-space: pre;
    font-size: 3.5rem;
  `,

  TitleColored: styled(Heading.h1)`

    @media (min-width: ${({ theme }) => theme.breakpoints.xl.media}) {
        font-size: 5.5rem;
    }

    color: ${props => props.theme.main};
  `,
  SubTitleColored: styled(Subtitle.h1)`
    line-height: 1.3;
    color: ${props => props.theme.main};
  `,
  SubTitleColoredInner: styled(Subtitle.h1)`
    color: ${props => props.theme.accent};
  `,
  CustomParallax: styled(Parallax)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & > div {
      display: flex;
      justify-content: center;
      & svg {
        height: 100%;
        width: 100%;
        margin-left: 2rem;
        transform: rotateZ(2deg);
      }
    }
  `,

  ParallaxContainer: Watch(styled(Col)`
    @media (max-width: ${({ theme }) => theme.breakpoints.xl.media}) {
      margin-top: 2rem;
    } 
    @media (max-height: ${({ theme }) => theme.breakpoints.xs.height}) {
      margin-top: -2rem;
    }
  `),
  ParallaxContainerOuter: Watch(styled(Col)`
    height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: auto;
    background: ${({ theme }) => theme.background};
    overflow: hidden;
  `),
  ParallaxContainerInner: Watch(styled.div`
    position: absolute;
    transform: translate(-50%,-44%);
   
    @media (max-height: ${({ theme }) => theme.breakpoints.xxl.height}) {
      transform: translate(-50%,-48%);
    }
    @media (max-height: ${({ theme }) => theme.breakpoints.xl.height}) {
      transform: translate(-50%,-50%);
    }
    @media (max-height: ${({ theme }) => theme.breakpoints.lg.height}) {
      transform: translate(-50%,-55%);
    }
    @media (max-height: ${({ theme }) => theme.breakpoints.md.height}) {
      transform: translate(-50%,-58%);
    }
    @media (max-height: ${({ theme }) => theme.breakpoints.md.height}) and (min-width: ${({ theme }) => theme.breakpoints.md.media}) {
      transform: translate(-50%,-54%);
    }  
    @media (max-height: ${({ theme }) => theme.breakpoints.sm.height}) {
      transform: translate(-50%,-60%);
    }
    @media (max-height: ${({ theme }) => theme.breakpoints.sm.height}) and (min-width: ${({ theme }) => theme.breakpoints.xs.media}) {
      transform: translate(-50%,-59%);
    }  
    @media (max-height: ${({ theme }) => theme.breakpoints.sm.height}) and (min-width: ${({ theme }) => theme.breakpoints.sm.media}) {
      transform: translate(-50%,-58%);
    }  
    @media (max-height: ${({ theme }) => theme.breakpoints.sm.height}) and (min-width: ${({ theme }) => theme.breakpoints.md.media}) {
      transform: translate(-50%,-58%);
    }  
    @media (max-height: ${({ theme }) => theme.breakpoints.sm.height}) and (min-width: ${({ theme }) => theme.breakpoints.xl.media}) {
      transform: translate(-50%,-54%);
    }  
    @media (max-height: ${({ theme }) => theme.breakpoints.xs.height}) {
      transform: translate(-50%,-65%);
    }
    @media (max-height: ${({ theme }) => theme.breakpoints.xs.height}) and (min-width: ${({ theme }) => theme.breakpoints.sm.width}) {
      transform: translate(-50%,-61%);
    }  
    @media (max-height: ${({ theme }) => theme.breakpoints.xxs.height}) {
      transform: translate(-50%,-68%);
    }
  `),
  /* background: ${(props) => props.theme.ourblack} */
  SectionWrapper: styled(Row)`
    padding-top: 20rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.xl.media}) {
      padding-top: 30rem;
    }
  `,

  Box: styled(animated.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.primary};
    height: 50%;
    transform: translateY(-50%);
    transform-origin: 0% 50%;
  `,
  Rect: Keyframes.Spring.to([
    { delay: 1500, from: { x: 0 }, to: { x: 1 } },
    { to: { x: 0, transformOrigin: '100% 50%' } }
  ]),

  Content: Keyframes.Trail([
    { delay: 1900, from: { x: -30, opacity: 0 }, to: { x: 0, opacity: 1 } }
  ]),
  fast: {
    ...config.stiff,
    restSpeedThreshold: 0.1,
    restDisplacementThreshold: 0.01
  }
}

import Home from 'views/home'

const routes = [
  {
    path: '/',
    Component: Home,
    exact: true
  }
]

export default routes

import styled, { css } from 'styled-components'
import { Paragraph } from 'styles/typography'
import { Col, Row } from 'styles/flexboxgrid'
import { mq } from 'utils/mq'

export const STC = {
  Text: styled(Paragraph)`
    color: ${props => props.theme.dark}
    font-size: 1.4rem;
    font-family: Rubik;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;

    ${mq(
    'min',
    'sm',
    css`
        font-size: 1.4rem;
      `
  )};
  ${mq(
    'min',
    'md',
    css`
      font-size: 1.2rem;
    `
  )};

  `,

  Span: styled.p`
    font-size: 1.4rem;
    font-family: Rubik;
    font-weight: 400;
    font-style: normal;
    color: ${props => props.theme.primary}
    text-transform: none;

    text-transform: uppercase;

    ${mq(
    'min',
    'sm',
    css`
        font-size: 1.4rem;
      `
  )};
  ${mq(
    'min',
    'md',
    css`
      font-size: 1.2rem;
    `
  )};
  `,

  Information: styled(Row)`
    margin-top: 10rem;
    margin-bottom: 5rem;
  `,

  Circle: styled.span`
    color: ${props => props.theme.dark}
    font-size: 1.4rem;
    font-family: Rubik-Medium;
    padding-right: 1rem;
    padding-left: 1rem;
  `,

  ContainerSocial: styled(Col)`
    ${mq(
    'min',
    'lg',
    css`
        text-align: center;
      `
  )};
  `,
  ContainerLogo: styled(Col)`
    ${mq(
    'min',
    'lg',
    css`
        text-align: left;
      `
  )};
  `,
  ContainerPosition: styled(Col)`
    ${mq(
    'min',
    'lg',
    css`
        text-align: right;
      `
  )};
  `
}

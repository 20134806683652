import styled, { css } from 'styled-components'
// import { mq } from 'utils/mq'
// import { STC } from './style'
// import styled, { css } from 'styled-components'
/**
 * [mq - Media queries styled components function]
 * @param  {[string]}   maxormin   [max or min]
 * @param  {[sting]}   breakpoint [xs - sm - md - lg - xl]
 * @param  {stylecss} stylecss   [all css stylecss]
 *
 * example usage
 * ${mq('max', 'sm', css`
       font-size: 3rem;
     `
   )};
 */
export const mq = (maxormin, breakpoint, ...stylecss) => {
  return css`
      @media only screen and (${maxormin}-width: ${({ theme }) =>
  theme.breakpoints[breakpoint].media}) {
        ${css(...stylecss)};
      }
    `
}

const sizes = {
  sm: '576',
  md: '768',
  lg: '992',
  xl: '1200',
  xxl: '1700'
}

// Iterate through the sizes and create a media template
export const mediaMaxW = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `

  return acc
}, {})

export const mediaMinW = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `

  return acc
}, {})

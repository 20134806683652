import styled from 'styled-components'

export const STC = {
  SvgWrapper: styled.div`
    position: absolute;
    top: -500px;
    left: 0;
    width: 50rem;
    height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  `
}

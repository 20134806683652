import anime from 'animejs'
import React, { Component } from 'react'
import styled from 'styled-components'

const SvgWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 110rem;
  bottom: -35rem;
  left: 0;
  z-index: -1;
`

const Svg = styled.svg`
  /* fill: #222831; */
  fill: #ffffff;
  height: 100%;
`

const morph = [
  {
    value:
      'M0,137.08091 C172.007372,162.872492 440.007372,202.101 804,254.766434 C1349.98894,333.764584 1719,-12.2335661 3054,216.36207 C3054,513.809198 3054,850.36161 3054,1226.01931 L0,1226.01931 L0,137.08091 Z'
  },
  {
    value:
      'M0,118.099967 C302.666667,278.699989 663.333333,321.666667 1082,247 C1710,135 1857,135 3054,197.381127 C3054,494.828254 3054,821.380667 3054,1177.03836 L0,1177.03836 L0,118.099967 Z'
  },
  {
    value:
      'M0,132.774074 C393.333333,146.258025 782.666667,177 1168,225 C1746,297 2088,331.71884 3054,132.774074 C3054,430.221202 3054,779.867334 3054,1181.71247 L0,1181.71247 L0,132.774074 Z'
  }
]

class SvgWrapperTab extends Component {
  constructor (props) {
    super(props)
    this.state = {
      morphIndex:
        props.index || Math.floor(Math.random() * (morph.length - 1 - 0) + 0)
    }
    this.svg = React.createRef()
  }

  initialize = () => {
    const pathToAnime = this.props.full
      ? morph
      : [morph[Math.floor(Math.random() * (morph.length - 1 - 0) + 0)]]
    anime({
      targets: this.svg.current.children[0],
      d: [...pathToAnime, morph[this.state.morphIndex]],
      easing: 'easeInOutQuad',
      duration: this.props.time || 30000,
      loop: true,
      direction: 'alternate'
    })
  }

  getProperty = (element, property) =>
    +window.getComputedStyle(element)[property].slice(0, -2)

  componentDidMount = () => {
    setTimeout(() => {
      if (this.props.stop === undefined) this.initialize()
    }, 100)
  }

  render () {
    return (
      <SvgWrapper
        style={{
          ...(this.props.noCss && { left: 'auto', top: 'auto' }),
          ...this.props.style
        }}
        left={this.props.left}
        x={this.props.x || '1'}
        y={this.props.y || '1'}
      >
        <Svg
          ref={this.svg}
          style={{ opacity: this.props.opacity || 1 }}
          viewBox={`0 0 ${this.props.viewboxW || 3054} ${this.props.viewboxH ||
            1250}`}
        >
          <path d={morph[this.state.morphIndex].value} />
        </Svg>
      </SvgWrapper>
    )
  }
}

export default SvgWrapperTab

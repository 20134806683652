import React from 'react'
import { withTheme } from 'styled-components'

const SvgComponent = props => (
  <svg
    viewBox="0 0 60 36"
    width={20}
    height={8}
    {...props}
    alt="Icona freccia destra"
  >
    <path
      d="M.625 15.957c-.417.417-.625.99-.625 1.72 0 .728.234 1.327.703 1.796.469.469 1.068.703 1.797.703h48.906L40.781 30.957c-1.25 1.146-1.276 2.318-.078 3.516s2.37 1.172 3.516-.078l15-15c.312-.208.52-.469.625-.781a2.95 2.95 0 0 0 .156-.938 2.95 2.95 0 0 0-.156-.937c-.104-.313-.313-.573-.625-.782l-15-15C43.073-.293 41.9-.319 40.703.88s-1.172 2.37.078 3.516l10.625 10.781H2.5c-.73 0-1.354.26-1.875.781z"
      fill={props.theme.accent}
      fillRule="evenodd"
    />
  </svg>
)

export default withTheme(SvgComponent)

import styled, { css } from 'styled-components'
import { Col, Row } from 'styles/flexboxgrid'
import { mq } from 'utils/mq'

export const STC = {
  NavFooter: styled(Row)`
    margin-top: 30rem;
    ${mq(
    'max',
    'md',
    css`
        display: none;
        margin-top: 10rem;
        align-items: flex-start;
      `
  )};
  `,
  Wrapper: styled(Col)`
    margin-top: 2rem;
  `
}

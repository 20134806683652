import React from 'react'
import { STC } from './style'

export default ({ to, text, onClick = () => {} }) => (
  <STC.BaseNavLink
    to={to}
    activeStyle={{
      opacity: 1
    }}
    onClick={onClick}
  >
    {text}
  </STC.BaseNavLink>
)

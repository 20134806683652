import styled, { css } from 'styled-components'
import { Heading, Subtitle } from 'styles/typography'
import { Row } from 'styles/flexboxgrid'
import Coffe from 'assets/svg/Coffe'
import { mq } from 'utils/mq'

export const STC = {
  Head: styled(Row)`
    margin-top: 30rem;
    ${mq(
    'max',
    'sm',
    css`
        margin-top: 20rem;
      `
  )};
  `,
  Title: styled(Heading.h3)`
      color: ${props => props.theme.dark}
    position: relative;
    text-transform: uppercase;
    font-size: 2.3rem;
    ${mq(
    'max',
    'sm',
    css`
        font-size: 1.8rem;
      `
  )};
  
    ${mq(
    'max',
    'md',
    css`
        font-size: 1.9rem;
      `
  )};
  
  `,
  Sub: styled(Subtitle.h2)`
    font-family: Rubik;
    font-weight: 400;
    color: ${props => props.theme.primary}
    text-transform: uppercase;
    font-size: 2rem;
    ${mq(
    'max',
    'sm',
    css`
        font-size: 1.8rem;
      `
  )};

    ${mq(
    'max',
    'md',
    css`
        font-size: 1.9rem;
      `
  )};
  
  `,
  CoffeIcon: styled(Coffe)`
    display: inline-block;
  `
}

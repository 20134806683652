import styled, { css } from 'styled-components'
import { Heading } from 'styles/typography'
import { Row } from 'styles/flexboxgrid'
import { mq } from 'utils/mq'

export const STC = {
  Mail: styled(Row)`
    margin-top: 10rem;
  `,
  H1: styled(Heading.h1)`
    text-decoration: none;
    color: ${props => props.theme.dark};

    ${mq(
    'max',
    'sm',
    css`
        font-size: 3rem;
      `
  )};
  `
}

import styled, { keyframes } from 'styled-components'

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const STC = {
  Wrapper: styled.div`
      opacity: 0;
      animation: ${FadeIn} 1000ms cubic-bezier(0.86, 0, 0.07, 1);
      animation-delay: 1s;
      animation-fill-mode: forwards;

      .fade-enter {
          opacity: 0;
          &.fade-enter-active {
              opacity: 1;
              transition: opacity 300ms cubic-bezier(0.86, 0, 0.07, 1) 0.3s;
          }
      }
      .fade-exit {
          opacity: 1;
          &.fade-exit-active {
              opacity: 0;
              transition: opacity 300ms cubic-bezier(0.86, 0, 0.07, 1);
          }
      }
    }

    .transition-group {
      position: relative;
    }
    .wrapper-absolute {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      width: 100%;
    }
  `
}

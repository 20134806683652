import React from 'react'

const Coffe = props => (
  <svg
    viewBox="0 0 55 77"
    width="4rem"
    height="4rem"
    {...props}
    alt="Icona caffè"
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M42.49 38.87a20.07 20.07 0 0 1 5.25-.12 6.78 6.78 0 0 1 4.5 2.45c1.34 1.8 1.33 4.23 1.26 6.46l-.24 8.72a10.89 10.89 0 0 1-1 5.13 6.88 6.88 0 0 1-3.49 2.83 14.5 14.5 0 0 1-4.48.83 3 3 0 0 1-2-.4 2.11 2.11 0 0 1-.29-2.66 5.49 5.49 0 0 1 2.23-1.83 8.3 8.3 0 0 0 2.39-1.65c1.06-1.24 1.15-3 1.19-4.66l.11-5c0-1.13 0-2.41-.83-3.18a5 5 0 0 0-2.95-.86c-1-.12-2.23-.55-2.49-1.57a3 3 0 0 1 .28-1.76l1.29-3.36"
        fill="#E5EEFD"
      />
      <path d="M45.81 58.8h1.31a.6.6 0 0 0 .58-.61V46.62" fill="#E5EEFD" />
      <path
        d="M45.81 58.26a3.94 3.94 0 0 1 .86-.41l.43-.13a.51.51 0 0 0-.63.39s0 .11 0 0v-.32c0-.29 0-.57-.06-.86a26.48 26.48 0 0 1 0-3.45 49.15 49.15 0 0 1 .75-6.91.56.56 0 0 1 .65-.44.59.59 0 0 1 .45.44c.402 2.3.649 4.626.74 6.96A30.55 30.55 0 0 1 49 57l-.06.86-.06.59a1.7 1.7 0 0 1-1.81 1.45l-.43-.13a4.53 4.53 0 0 1-.86-.42.62.62 0 0 1-.18-.88.66.66 0 0 1 .21-.21z"
        fill="#062342"
      />
      <path
        d="M46.12 38.21a5.1 5.1 0 0 1 1.08-.3 4.49 4.49 0 0 1 1.27 0 5.88 5.88 0 0 1 2.39.86 7.77 7.77 0 0 1 3.69 6.35v12.91a9 9 0 0 1-.36 2.49 7.61 7.61 0 0 1-3 4.06 6.2 6.2 0 0 1-2.37.92 5.53 5.53 0 0 1-2.39-.14.66.66 0 0 1-.44-.83.65.65 0 0 1 .51-.53 8.76 8.76 0 0 0 1.86-.55 6.1 6.1 0 0 0 1.49-.9 5.63 5.63 0 0 0 1.89-2.82 6.3 6.3 0 0 0 .2-1.76v-6.41-4.29-2.11a5.25 5.25 0 0 0-.29-1.71 5.17 5.17 0 0 0-2-2.72A7.44 7.44 0 0 0 48 40l-.42-.14a3.6 3.6 0 0 1-.44-.12 6.38 6.38 0 0 1-1.06-.32.65.65 0 0 1 0-1.22l.04.01z"
        fill="#062342"
      />
      <path
        d="M44.84 29.17v42.08a4.3 4.3 0 0 1-4.3 4.3h-35a4.3 4.3 0 0 1-4.29-4.3V29.17h43.59z"
        fill="#FFF"
      />
      <path
        d="M44.84 29.17v42.08a4.3 4.3 0 0 1-4.3 4.3h-3V29.17h7.3z"
        fill="#E5EEFD"
      />
      <path
        d="M21.37 28.65a37.88 37.88 0 0 1 5.87-.65c2-.08 3.91-.12 5.86-.15 1.95-.03 3.91 0 5.87 0s3.91.06 5.87.14a1.25 1.25 0 0 1 1.19 1.24v41.11a6.91 6.91 0 0 1-6.5 6.44h-33A7 7 0 0 1 0 70.35V29.17A1.22 1.22 0 0 1 1.08 28h.16a12.93 12.93 0 0 1 3.23 0 27.45 27.45 0 0 1 3.23.51.68.68 0 0 1 .52.81.69.69 0 0 1-.52.51 27.45 27.45 0 0 1-3.23.51 13.42 13.42 0 0 1-3.23.07l1.24-1.24v40.99a4.45 4.45 0 0 0 4.2 4.13H39.4A4.45 4.45 0 0 0 42.27 73a4.5 4.5 0 0 0 1.31-2.85V29.21l1.25 1.24c-2 .08-3.92.14-5.87.13-1.95-.01-3.91 0-5.87 0s-3.91-.07-5.86-.16a36.68 36.68 0 0 1-5.87-.68.53.53 0 0 1 0-1l.01-.09z"
        fill="#062342"
      />
      <path
        d="M12.86 28.14a1.29 1.29 0 0 1 .76-.22.94.94 0 0 1 .76.33 1.37 1.37 0 0 1 0 1.83 1 1 0 0 1-.76.34 1.36 1.36 0 0 1-.76-.22 1.25 1.25 0 0 1-.33-1.74c.087-.128.2-.237.33-.32z"
        fill="#062342"
      />
      <g opacity={0.5}>
        <path d="M36.35 22c-5-4.86 4.29-12.79.09-21.41" fill="#E5EEFD" />
        <path
          d="M36 22.32a5.37 5.37 0 0 1-1.64-2.58 7.42 7.42 0 0 1-.13-3 44.36 44.36 0 0 1 1.38-5.51A20.28 20.28 0 0 0 36.57 6a18.1 18.1 0 0 0-.74-5.12.69.69 0 0 1 1.22-.59A9 9 0 0 1 38.54 6a22.58 22.58 0 0 1-1.2 5.69A52.3 52.3 0 0 0 35.69 17a6.91 6.91 0 0 0-.12 2.51 4.56 4.56 0 0 0 1.1 2.19.463.463 0 0 1-.64.67l-.03-.05z"
          fill="#A3ACD6"
        />
      </g>
    </g>
  </svg>
)

export default Coffe

import React from 'react'
import { STC } from './style'

const Line = React.forwardRef(
  (
    { bottom = '1.2rem', left = '-7rem', width = '5rem', relative, style },
    ref
  ) => (
    <STC.LineStyle
      bottom={bottom}
      left={left}
      width={width}
      relative={relative}
      ref={ref}
      style={{ ...style }}
    />
  )
)

export default Line

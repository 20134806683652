import styled, { css } from 'styled-components'

export const STC = {
  HamburgerContainer: styled.div`
    position: relative;
    top: 3px;
    width: 26px;
    height: 26px;
    transition: all 300ms cubic-bezier(0.61, 0.01, 0.42, 1);
    cursor: pointer;
    border: 0px;

    ${({ opened }) =>
    opened &&
      css`
        &:not:hover div:nth-child(3):before,
        &:not:hover div:nth-child(3):before,
        &:not:hover div:nth-child(2) {
          opacity: 0;
          transition: all 200ms;
        }

        div {
          border: 1px solid #fff;
          background: #fff;
          height: 0px;
          border-radius: 0%;
          margin-left: -1px;
          margin-top: 6px;
          width: 25px;
          top: 0px;
        }

        div:nth-child(2) {
          width: 0;
          border: 0px;
        }

        div:nth-child(3) {
          transform: rotate(-45deg);
        }

        div:nth-child(1) {
          transform: rotate(45deg);
        }
      `} /* Atomo ↓ */;
  `,
  HamburgerSpan: styled.div`
    height: 0px;
    border: 1px solid ${({ theme }) => theme.main};
    width: 22px;
    display: block;
    position: absolute;
    transition: all 300ms cubic-bezier(0.61, 0.01, 0.42, 1);
    background: ${({ theme }) => theme.main};

    &:nth-child(1) {
      top: 0px;
      width: 100%;
    }

    &:nth-child(2) {
      top: 8px;
      width: 70%;
    }

    ${({ opened }) =>
    !opened &&
      css`
        &:nth-child(3) {
          top: 16px;
          width: 40%;
        }
      `} &:nth-child(3):before {
      opacity: 0;
      content: "";
      border: 1px solid ${({ theme }) => theme.main};
      display: block;
      position: relative;
      top: 0.25px;
    }
  `
}

import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const STC = {
  Container: styled.div`
    position: absolute;
    z-index: 11;
    top: 2rem;
    right: 5rem;
  `,
  LangBTN: styled.button`
    color: ${({ theme }) => theme.main};
    font-family: Rubik-Medium;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.5px;
    font-size: 1.1rem;
  `,
  Circle: styled.span`
    color: ${({ theme }) => theme.main};
    font-size: 1.4rem;
    font-family: Rubik-Medium;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  `
}

import React, { PureComponent } from 'react'
import { Heading, Paragraph, Subtitle } from 'styles/typography'
import { Col, Row, Grid } from 'styles/flexboxgrid'
import Line from 'components/line'
import { animated, config } from 'react-spring'
import { STC } from './style'
import { theme } from '../../../../styles/global'
import LogoScrum from 'assets/images/scrummaster.png'

const items = [
  props => (
    <Heading.h3 as="h3" color="#fff" style={{ marginTop: '5rem' }}>
      Agile methods driven
    </Heading.h3>
  ),

  props => (
    <Paragraph color="#fff" style={{ marginBottom: '3rem' }}>
      Developing software is really complex. Request changes, people changes, information at specified time changes, thus we must be alwasys fast and able to react. Most of the projects in which I was involved was using Agile scrum methodologies. That's why getting a deeper knowledge was crucial to me to help deliver software at best.
    </Paragraph>
  ),
  () => <img src={LogoScrum} style={{height: '4rem', top: '-2.5rem', left: '0rem', position: 'relative'}} />

  // props => <STC.ColoredButton text="In che modo lavoriamo" to="/metodo" />
]

class Section3 extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isFullyInViewport: undefined
    }
  }

  render () {
    const state =
      this.state.isFullyInViewport === undefined
        ? 'notInView'
        : this.state.isFullyInViewport === true
          ? 'inView'
          : 'notInView'

    return (
      <STC.Wrapper>
        <Grid id="certifications">
          <STC.Section
            pd_t="20"
            pd_b="20"
            mrg_t="20"
            center
            middle
            innerRef={(el, watcher, prop) => {
              this.props.registerWatcher(5, watcher)
            }}
            fullyEnterViewport={(watcher, childProps) => {
              this.setState({ isFullyInViewport: true })
              this.props.getItemInViewport(5, watcher)
            }}
            partiallyExitViewport={(watcher, childProps) => {
              this.props.removeCurrentItemFromViewport(5)
            }}
            exitViewport={(watcher, childProps) => {
              this.setState({ isFullyInViewport: false })
            }}
          >
            <Col md="12" lg="4" mrg_t="6">
              <STC.ContentTrail
                native
                keys={items.map((_, i) => i)}
                config={config.slow}
                state={state}
              >
                {items.map((Item, i) => ({ x, ...props }) => (
                  <animated.div
                    style={{
                      ...props
                    }}
                  >
                    <Item {...this.props} />
                  </animated.div>
                ))}
              </STC.ContentTrail>
            </Col>
            <STC.Card md="10" lg="6" lg_off="2">
              <Row middle center>
                <Col xs="8" mrg_t="6" mrg_b="6">
                  <STC.Blockquote
                    as="blockquote"
                    style={{ position: 'relative', color: theme.dark }}
                  >
                    "A perfection of means, and confusion of aims, seems to be our main problem."
                    <Line bottom={'-3rem'} width={'6rem'} left={'1'} />
                  </STC.Blockquote>

                  <Subtitle.h2 style={{ marginTop: '6rem' }}>
                    Albert Einstein
                  </Subtitle.h2>
                  {/* <Paragraph style={{marginTop: '5rem'}} >Comunicazione diretta</Paragraph> */}
                </Col>
              </Row>
            </STC.Card>
          </STC.Section>
        </Grid>
      </STC.Wrapper>
    )
  }
}

export default Section3

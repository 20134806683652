import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Row } from 'styles/flexboxgrid'
import { Keyframes, animated, config, Spring } from 'react-spring'
import { sleep } from 'utils/sleep'

export const STC = {
  Pill: styled(Link)`
    font-size: 1.6rem;
    font-weight: 600;
    display: block;
    font-family: "Noto Sans", sans-serif;
    text-decoration: none;
    color: ${({ theme }) => theme.main};
    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.primary};
      transform: scale(0.98);
    }
  `,
  CardWrapper: styled(Row)`
    position: relative;
    margin-top: 10rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm.media}) {
      margin-top: 15rem;
    }

    & > :nth-child(1) {
    }
    & > :nth-child(2) {
      @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
        margin-top: 6rem;
      }
    }
  `,

  CardWrapperOverlay: styled(Row)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: flex-start;
    z-index: 5;
    @media (max-width: ${({ theme }) => theme.breakpoints.md.media}) {
      align-items: flex-start;
      justify-content: center;
    }
  `,
  SpringWrapper: styled(animated.div)`
    opacity: 0;
  `,

  fast: {
    ...config.stiff,
    restSpeedThreshold: 0.1,
    restDisplacementThreshold: 0.01
  },

  Content: Keyframes.Spring.to({
    inView: async next => {
      await sleep(200)
      next({ from: { x: -10, opacity: 0 }, to: { x: 0, opacity: 1 } })
      await sleep(200)
      await next({
        from: { y: -10, opacityInside: 0 },
        to: { y: 0, opacityInside: 1 }
      })
    },
    notInView: async next => {
      await next({
        from: { y: 0, opacityInside: 1, opacity: 1, x: 1 },
        to: { y: -10, opacityInside: 0 }
      })
      await next({ from: { x: 0, opacity: 1 }, to: { x: -10, opacity: 0 } })
    }
  }),

  Box: styled(animated.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.primary};
    transform-origin: 0% 50%;
    height: 8rem;
  `,

  Rect: Keyframes.Spring.to({
    inView: async next => {
      await sleep(500)
      await next({ from: { y: 0 }, to: { y: 1 } })
      // await next({ to: { transformOrigin: '100% 50%' } })
      await next({ from: { y: 1 }, to: { y: 0 } })
      await next({ to: { opacity: 0 } })
    }
  }),

  ContentDescription: Keyframes.Trail({
    inView: async next => {
      await sleep(500)
      next({ from: { x: -20, opacity: 0 }, to: { x: 0, opacity: 1 } })
    },
    notInView: async next => {
      await next({ from: { x: 0, opacity: 1 }, to: { x: -20, opacity: 0 } })
    }
  })
}

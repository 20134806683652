import styled, { css } from 'styled-components'
import Shevy from 'shevyjs'
import { defaultOptions } from 'styles/global'

const shevy = new Shevy(
  defaultOptions || {
    baseFontSize: '2rem',
    baseLineHeight: 1.5,
    baseFontScale: [3, 1.4, 2, 1.5, 1.25, 1], // [h1, h2, h3, h4, h5, h6]
    addMarginBottom: true,
    proximity: false,
    proximityFactor: 0.85
  }
)

const { baseSpacing: bs, h1, h2, h3, h4, h5, h6, body } = shevy

export const dm = bs

export const Heading = {
  h1: styled.h1`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 4.5rem;
    line-height: ${h1.lineHeight};
    margin-bottom: ${h1.marginBottom};
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h2: styled.h2`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: ${h2.lineHeight};
    margin-bottom: 0.4rem;
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h3: styled.h3`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: ${h3.lineHeight};
    margin-bottom: ${dm(0.5)};
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h4: styled.h4`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: ${h4.fontSize};
    line-height: ${h4.lineHeight};
    margin-bottom: ${dm(0.5)};
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h5: styled.h5`
    font-family: Rubik;
    font-style: normal;
    font-size: ${h5.fontSize};
    line-height: ${h5.lineHeight};
    margin-bottom: ${h5.marginBottom};
    opacity: 0.37;
    font-weight: 300;
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h6: styled.h6`
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: ${h6.fontSize};
    line-height: ${h6.lineHeight};
    margin-bottom: ${h6.marginBottom};
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `
}

export const Subtitle = {
  h1: styled.p`
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: ${h1.marginBottom};
    line-height: ${h1.lineHeight};
    color: #393e46;
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h2: styled.p`
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    /*font-size: 1.2rem;*/
    margin-bottom: 1rem;
    line-height: ${h2.lineHeight};
    color: ${props => props.theme.subtitle};
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h3: styled.p`
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 1rem;
    line-height: ${h3.lineHeight};
    color: #393e46;
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h4: styled.p`
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${h4.fontSize};
    margin-bottom: 1rem;
    line-height: ${h4.lineHeight};
    color: #393e46;
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h5: styled.p`
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${h5.fontSize};
    line-height: ${h5.lineHeight};
    margin-bottom: ${h5.marginBottom};
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `,
  h6: styled.p`
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${h6.fontSize};
    line-height: ${h6.lineHeight};
    margin-bottom: ${h6.marginBottom};
    ${({ color }) =>
    color &&
      css`
        color: ${color};
      `};
  `
}

export const Paragraph = styled.p`
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.7;
  margin-bottom: ${body.marginBottom};
  color: ${props => props.theme.main + '88'};
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};


  & > strong {
    font-family: 'Noto Sans', sans-serif;
    /*color: ${props => props.theme.subtitle};*/
    color: ${props => props.theme.main};
    font-size: 1.8rem;
    /*font-weight: 600;*/
  }
`

import React from 'react'
import { STC } from './style'

export default ({ title, to, text }) => (
  <STC.Wrap>
    <STC.Title style={{ marginBottom: '3rem' }}>{title}</STC.Title>
    <STC.Action onClick= {() => {
      window.open(to, '_blank').focus()
    }}>{text}</STC.Action>
  </STC.Wrap>
)

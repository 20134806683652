import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  position: relative;
  flex: none;
  fill: ${({ theme }) => theme.primary};
`

const SvgMorph = React.forwardRef((props, ref) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 500 500"
    {...props}
    ref={ref}
    alt="Fluido rosa"
  >
    <path
      // fill="url(#linearGradient-1)"
      // fill-opacity={0.7}
      d="M321.3125,383.758977 C311.3125,393.758977 307.3125,402.758977 284.3125,408.758977 C261.3125,414.758977 240.3125,411.758977 216.3125,393.758977 C192.3125,375.758977 187.3125,354.758977 193.3125,320.758977 C199.3125,286.758977 226.3125,274.758977 243.3125,269.758977 C260.3125,264.758977 312.3125,268.758977 327.3125,303.758977 C342.3125,338.758977 331.3125,373.758977 321.3125,383.758977 Z"
    />
  </Svg>
))

export default SvgMorph

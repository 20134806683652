import styled from 'styled-components'
import { Heading } from 'styles/typography'
import {BaseButton} from 'components/button'

export const STC = {
  Wrap: styled.div`
    position: relative;
    width: 100%;
    z-index: 1;
    margin-top: 20rem;
    text-align: center;
    margin-bottom: -20rem;
  `,

  Title: styled(Heading.h2)`
    color: ${({ theme }) => theme.main};
  `,

  Action: styled(BaseButton)`
    background: ${({ theme }) => theme.dark};
    color: ${({ theme }) => theme.main};
  `
}

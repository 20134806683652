import LogoAws from 'assets/images/aws.png'
import Body from 'assets/images/corpo.png'
import Brain from 'assets/images/lightBulb.png'
import Head from 'assets/images/testa.png'
import React, { Fragment, useEffect, useRef } from 'react'
import {
  MouseParallaxChild,
  MouseParallaxContainer
} from 'react-parallax-mouse'
import { Parallax } from 'react-scroll-parallax'
import { animated } from 'react-spring'
import { Col, Grid, Row } from 'styles/flexboxgrid'
import { Subtitle } from 'styles/typography'
import { STC } from './style'

const items = [
  <STC.TitleColored>
    An 🇮🇹 Full Stack
    <br /> <STC.TitleColored as="span">Software Engineer</STC.TitleColored>
  </STC.TitleColored>,
  <STC.SubTitleColored>
    With a good <STC.SubTitleColoredInner as={'span'}>head on his shoulders,</STC.SubTitleColoredInner>
    <br /> and some nice experiences.
    {/* <br />and Solution architect certified. */}
  </STC.SubTitleColored>,
  <img src={LogoAws} style={{height: '4rem', top: '-2.5rem', left: '-1.8rem', position: 'relative'}} />

]

const Section1 = props => {
  const nextEl = useRef(null)

  useEffect(() => {
    // 👇️ use document.getElementById()
    const el = document.getElementById('skills')
    nextEl.current = el
  }, [])

  const gotoNextSection = () => {
    function findPos (obj) {
      var curtop = 0
      if (obj && obj.offsetParent) {
        do {
          curtop += obj.offsetTop
        // eslint-disable-next-line no-cond-assign
        } while (obj = obj.offsetParent)
        return [curtop]
      }
    }
    window.scroll(0, findPos(nextEl.current))
  }

  return (
    <Fragment>
      <STC.DivColored>
        <Grid id="about-me">
          <STC.SectionWrapper mrg_t="5" pd_b="15">
            <Col sm="12" md="12" lg="6" xl="6" style={{ position: 'relative' }}>
              <STC.Rect native config={STC.fast}>
                {({ x, ...props }) => (
                  <STC.Box
                    style={{
                      transform: x.interpolate(x => `scaleX(${x})`),
                      ...props
                    }}
                  />
                )}
              </STC.Rect>
              <STC.Content width="110%" style={{marginBottom: '-2rem'}} native keys={items.map((_, i) => i)} config={STC.fast}>
                {items.map((item, i) => ({ x, ...props }) => (
                  <animated.div
                    style={{
                      transform: x.interpolate(x => `translate3d(${x}%,0,0)`),
                      ...props
                    }}
                  >
                    {item}
                  </animated.div>
                ))}
              </STC.Content>
            </Col>
            <STC.ParallaxContainer
              xs="12"
              sm="12"
              sm_off="0"
              md="10"
              md_off="1"
              lg="5"
              lg_off="1"
              xl="5"
              xl_off="1"
              innerRef={(el, watcher, prop) => {
                props.registerWatcher(0, watcher)
              }}
              fullyEnterViewport={(watcher, childProps) => {
                props.getItemInViewport(0, watcher)
              }}
              partiallyExitViewport={(watcher, childProps) => {
                props.removeCurrentItemFromViewport(0)
              }}
            >
              <Parallax >
                {/* <AppLaunch /> */}
                {/* <img src={Iphone}/> */}
                <STC.ParallaxContainerOuter>
                  <img src={Body} style={{height: '60vh'}} />
                </STC.ParallaxContainerOuter>
              </Parallax>
              <STC.CustomParallax
                translateX={[-70, -70]}
                translateY={[-100, -100]}
              >
                <img src={Brain} style={{height: '10vh'}} />
              </STC.CustomParallax>
              <STC.CustomParallax
                speed={10}
                easing={[1, 1, 0.5, 3]}
                translateX={[-50, -50]}

              >
                <STC.ParallaxContainerInner>
                  <MouseParallaxContainer
                    resetOnLeave
                  >
                    <MouseParallaxChild
                      factorX={0.07}
                      factorY={0.05}
                      updateStyles={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 'auto',
                        height: '100%'
                      }}
                    >
                      <img src={Head} style={{height: '60vh'}} />
                    </MouseParallaxChild>
                  </MouseParallaxContainer>
                </STC.ParallaxContainerInner>
              </STC.CustomParallax>
            </STC.ParallaxContainer>
          </STC.SectionWrapper>
        </Grid>
      </STC.DivColored>
      <STC.CaptionWrapper>
        <Row center_txt>
          <STC.Caption md="10" lg="8">
            <STC.CaptionTitle>
              {/* Like a Swiss army knife for your projects. */}
Grown as a consultant
            </STC.CaptionTitle>
            <Subtitle.h2 style={{ fontSize: '2.5rem', marginBottom: '5rem' }}>
            With 20+ medium-sized projects behind me for small and big companies and 3+ mega-sized projects. I collaborated with important firms in sectors like Banking-finance, healthcare, restaurant business, and startups.
            </Subtitle.h2>
            <STC.Action onClick={gotoNextSection} >Keep scrolling ↓ </STC.Action>
          </STC.Caption>
        </Row>
      </STC.CaptionWrapper>
    </Fragment>
  )
}

export default Section1

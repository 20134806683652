import styled from 'styled-components'

export const STC = {
  SvgWrapper: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    @media only screen and (max-width: ${({ theme }) =>
    theme.breakpoints.sm.media}) {
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
    }
  `,
  Svg: styled.svg`
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.primary};
  `
}

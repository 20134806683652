import { createGlobalStyle } from 'styled-components'
import ExtraStyle from './extraStyleInjected'
import reset from 'styled-reset'

const COLOR = {
  DEFAULT: '#062342',
  AMAZON_ORANGE: '#ff9900',
  DARKBLUE: '#0B0B15', // 0B0B45
  BLUELIGHT: '#3071f3',
  BLUE: '#1B71FB',
  RED: '#fd484a',
  PINK: '#ff708f',
  PINKLIGHT: '#FFB7C7',
  PINKGLAMOUR: '#ff6b6b',
  VIOLET: '#6c5ce7',
  LIGHTGREENIGHBLUE: '#55efc4',
  CYAN: '#01ADB5',
  PASTELRED: ' #ff7675',
  YELLOW: '#feca57',
  LIGHTBLUE: '#1ed0f2',
  GOLD: '#e6b759',
  WHITE: '#ffffff',
  LIGHTGRAY: '#0000000d',
  DARK: '#393E46',
  DARKGRAY: '#222831',
  BLACK: '#000',
  SUBTITLEGRAY: '#CBCFD3'
}

const darkTheme = {
  next: COLOR.LIGHTGREENIGHBLUE,
  main: COLOR.WHITE,
  background: COLOR.DARKBLUE,
  primary: COLOR.LIGHTGREENIGHBLUE,
  primary_2: COLOR.PINKLIGHT,
  accent: COLOR.GOLD,
  ourblack: COLOR.WHITE,
  dark: COLOR.DARKGRAY,
  subtitle: COLOR.SUBTITLEGRAY
}
const lightTheme = {
  next: COLOR.LIGHTGREENIGHBLUE,
  main: COLOR.DARK,
  background: COLOR.WHITE,
  primary: COLOR.AMAZON_ORANGE,
  primary_2: COLOR.PINKLIGHT,
  accent: COLOR.GOLD,
  ourblack: COLOR.DARKBLUE,
  dark: COLOR.WHITE,
  subtitle: COLOR.SUBTITLEGRAY
}

export const theme = {
  ...darkTheme,
  breakpoints: {
    xxs: {
      height: '667px'
    },
    xs: {
      width: 'auto',
      height: '750px'
    },
    sm: {
      media: '576px',
      width: '540px',
      height: '844px'
    },
    md: {
      media: '768px',
      width: '720px',
      height: '896px'
    },
    lg: {
      media: '992px',
      width: '960px',
      height: '915px'
    },
    xl: {
      media: '1200px',
      width: '1140px',
      height: '1025px'
    },
    xxl: {
      media: '1700px',
      width: '1140px',
      height: '1200px'
    }
  }
}

export const defaultOptions = {
  baseFontSize: '1.6rem',
  baseLineHeight: 1.5,
  baseFontScale: [3, 2.5, 2, 1.5, 1.25, 1],
  addMarginBottom: true,
  proximity: false,
  proximityFactor: 0.85
}

export const GlobalStyle = createGlobalStyle`

  ${reset}

  html {
    scroll-behavior: smooth;
    font-size: 62.5%; /* Now 10px = 1rem! */
    @media (max-width: ${({ theme }) => theme.breakpoints.xl.media}) {
      font-size: 55%;
    }
  }

  body {
    background: ${({ theme }) => theme.background};
    font-family: 'Rubik','Helvetica Neue';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    font-size: 1.6rem;
    overflow-x: hidden;
    /*color: ${({ theme }) => theme.main};*/
    color: ${props => (props.whiteColor ? '#fff' : props.theme.main)};
    line-height: 2;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

${ExtraStyle}
`

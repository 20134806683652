import App from 'App'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
// import store from 'redux/storeConfig'
import TagManager from 'react-gtm-module'
import WebFont from 'webfontloader'

const tagManagerArgs = {
  gtmId: 'GTM-THJQQ93'
}

TagManager.initialize(tagManagerArgs)

WebFont.load({
  google: {
    families: [
      'Rubik:300,400,500,700',
      'Noto Sans:300,400,500,700',
      'sans-serif'
    ]
  }
})

ReactDOM.render(
  <Router>
    {/* <Provider store={store}> */}
    <App />
    {/* </Provider> */}
  </Router>,
  document.getElementById('root')
)

//
// if (module.hot) {
//   module.hot.accept('./App', () => {
//     const NextApp = require('./App').default
//     ReactDOM.render(
//       <Router>
//         {/* <Provider store={store}> */}
//         <NextApp />
//         {/* </Provider> */}
//       </Router>,
//       document.getElementById('root')
//     )
//   })
// }

import React from 'react'
import { withTheme } from 'styled-components'

const SvgApp = props => (
  <svg viewBox="0 0 400 400" {...props} alt="Icona Applicazioni">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#FFF"
        strokeWidth={0.1}
        opacity={0.013}
        d="M.05.05h399.9v399.9H.05z"
      />
      <g opacity={0.5} transform="translate(239.168 95.75)">
        <rect
          stroke="#A3ACD6"
          strokeWidth={1.97}
          strokeLinecap="round"
          strokeLinejoin="round"
          x={0.078}
          y={0.069}
          width={33.151}
          height={27.578}
          rx={1.55}
        />
        <rect
          stroke="#A3ACD6"
          strokeWidth={1.97}
          strokeLinecap="round"
          strokeLinejoin="round"
          x={0.078}
          y={0.069}
          width={33.151}
          height={27.578}
          rx={1.55}
        />
        <path d="M16.767 9.123l12.236.061" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M16.775 8.672a23.731 23.731 0 0 1 6.088-.339c2.044.061 4.078.183 6.087.443a.425.425 0 0 1 .357.46.408.408 0 0 1-.357.356c-2.043.243-4.078.347-6.087.39a23.784 23.784 0 0 1-6.088-.407.46.46 0 0 1-.339-.565.487.487 0 0 1 .34-.338z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M4.426 9.089l8.819.026" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M4.426 8.576a15.429 15.429 0 0 1 4.41-.312c1.475.026 2.947.14 4.409.338a.521.521 0 0 1 0 1.025c-1.466.186-2.94.29-4.418.312-1.479.1-2.963-.015-4.41-.338a.53.53 0 1 1 .009-1.024z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M16.775 13.342l12.228.06" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M16.775 12.89a23.766 23.766 0 0 1 6.088-.347c2.044.07 4.078.183 6.087.452a.408.408 0 0 1 .348.46.417.417 0 0 1-.356.356c-2.044.243-4.079.338-6.088.382a23.4 23.4 0 0 1-6.087-.4.46.46 0 0 1 0-.868l.008-.034z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M4.426 13.299l8.819.026" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M4.426 12.786a15.429 15.429 0 0 1 4.41-.312 41.87 41.87 0 0 1 4.409.339.521.521 0 0 1 0 1.033 39.596 39.596 0 0 1-4.41.303 15.734 15.734 0 0 1-4.409-.33.538.538 0 0 1 0-1.033z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M16.775 17.552l12.228.06" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M16.775 17.1a23.801 23.801 0 0 1 6.088-.338c2.035.06 4.07.182 6.087.443.221.036.374.238.348.46a.408.408 0 0 1-.356.356c-2.035.243-4.079.347-6.088.39a23.853 23.853 0 0 1-6.087-.407.46.46 0 0 1-.33-.565.46.46 0 0 1 .338-.338z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M4.426 17.517l8.819.026" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M4.426 17.005a15.429 15.429 0 0 1 4.41-.312c1.475.026 2.947.14 4.409.338a.521.521 0 0 1 .452.582.521.521 0 0 1-.452.443c-1.463.186-2.935.29-4.41.312-1.478.1-2.963-.015-4.409-.338a.538.538 0 0 1-.373-.66.53.53 0 0 1 .373-.365z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M16.775 21.77l6.731.036" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M16.775 21.32a7.37 7.37 0 0 1 3.375-.356c1.132.046 2.258.191 3.365.434.223.055.36.28.304.503a.391.391 0 0 1-.313.304c-1.107.234-2.233.37-3.365.408-1.137.133-2.29 0-3.366-.39a.503.503 0 0 1-.252-.66c.051-.11.141-.197.252-.244z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M4.426 21.727l5.444.018" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M4.426 21.215a6.01 6.01 0 0 1 2.722-.312c.919.028 1.833.139 2.731.33a.53.53 0 0 1 .4.625.521.521 0 0 1-.409.399c-.895.188-1.806.296-2.722.321a6.098 6.098 0 0 1-2.722-.33.565.565 0 0 1 0-1.033z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M4.4 5.382l24.803.104" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M4.41 4.991c2.063-.086 4.13-.153 6.2-.2l6.2-.112 6.235-.06a40.137 40.137 0 0 1 6.201.286.583.583 0 0 1 .496.66.6.6 0 0 1-.505.503c-2.057.238-4.13.316-6.2.234l-6.2-.104-6.201-.174c-2.07-.06-4.131-.139-6.2-.243a.4.4 0 0 1-.375-.416.39.39 0 0 1 .348-.374z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
      </g>
      <g opacity={0.5} transform="translate(70.257 174.955)">
        <rect
          stroke="#A3ACD6"
          strokeWidth={1.97}
          strokeLinecap="round"
          strokeLinejoin="round"
          x={0.043}
          y={0.243}
          width={33.151}
          height={27.521}
          rx={1.55}
        />
        <path d="M16.732 9.278l12.236.06" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M16.74 8.827a23.413 23.413 0 0 1 6.088-.346c2.044.069 4.079.182 6.088.45a.41.41 0 0 1 .356.46.408.408 0 0 1-.356.346 60.499 60.499 0 0 1-6.088.39 23.448 23.448 0 0 1-6.087-.399.468.468 0 0 1-.34-.563.46.46 0 0 1 .34-.338z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M4.383 9.234l8.827.026" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M4.392 8.723A15.46 15.46 0 0 1 8.8 8.411c1.475.031 2.947.144 4.409.338a.52.52 0 0 1 0 1.022c-1.465.19-2.94.295-4.418.312a15.477 15.477 0 0 1-4.409-.338.537.537 0 0 1-.374-.65.52.52 0 0 1 .383-.372z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path
          d="M16.732 13.479l12.236.06M4.392 13.444l8.818.026"
          fill="#E5EEFD"
          fillRule="nonzero"
        />
        <path
          d="M4.392 12.924a15.451 15.451 0 0 1 4.409-.303 39.73 39.73 0 0 1 4.409.33.52.52 0 0 1 0 1.03c-1.466.182-2.94.286-4.418.312a15.477 15.477 0 0 1-4.409-.338.53.53 0 0 1-.365-.658.555.555 0 0 1 .374-.373z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path
          d="M16.74 17.689l12.228.06M4.392 17.646l8.818.026"
          fill="#E5EEFD"
          fillRule="nonzero"
        />
        <path
          d="M4.392 17.134a15.46 15.46 0 0 1 4.409-.311c1.475.03 2.947.143 4.409.337a.52.52 0 0 1 0 1.023c-1.462.19-2.935.294-4.41.311a15.538 15.538 0 0 1-4.417-.337.528.528 0 0 1-.365-.65.52.52 0 0 1 .374-.373z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M16.74 21.89l6.732.035" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M16.74 21.44a7.358 7.358 0 0 1 3.366-.355c1.132.046 2.259.19 3.366.433a.417.417 0 0 1 0 .814c-1.111.231-2.24.365-3.375.398a7.27 7.27 0 0 1-3.365-.39.493.493 0 0 1-.244-.649.547.547 0 0 1 .253-.251z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M4.392 21.856l5.444.008" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M4.392 21.336a6.022 6.022 0 0 1 2.722-.312c.915.028 1.826.138 2.722.33a.522.522 0 0 1 .4.623.53.53 0 0 1-.4.407c-.897.18-1.808.285-2.722.312a6.11 6.11 0 0 1-2.722-.33.564.564 0 0 1-.287-.744.608.608 0 0 1 .287-.286z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
        <path d="M4.366 5.544l24.802.095" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M4.366 5.146c2.07-.087 4.14-.148 6.209-.2l6.2-.112 6.2-.061c2.07-.06 4.139.035 6.193.286a.59.59 0 0 1 0 1.16c-2.058.238-4.131.317-6.2.235l-6.201-.104-6.2-.165c-2.07-.07-4.132-.147-6.201-.251a.399.399 0 0 1 0-.788z"
          fill="#A3ACD6"
          fillRule="nonzero"
        />
      </g>
      <path
        d="M178.981 280.721c-1.669 1.23-3.615 1.721-5.474 2.444l-5.63 2.023-11.244 4.053-11.305 3.916-5.647 1.962a37.605 37.605 0 0 1-5.795 1.575.486.486 0 0 1-.562-.396.498.498 0 0 1 .242-.508 38.33 38.33 0 0 1 5.466-2.46l5.613-2.075 11.244-4.165 11.243-4.037 5.63-2.014c1.886-.636 3.702-1.488 5.787-1.6a.712.712 0 1 1 .458 1.282h-.026zM183.572 297.854l-.132-18.689v-18.688l.053-18.688V227.768a18.789 18.789 0 0 1 .5-4.702.448.448 0 0 1 .544-.314.455.455 0 0 1 .316.314c.369 1.528.54 3.096.51 4.667V241.754l.052 18.688v18.689l-.14 18.688c0 .48-.393.87-.878.87a.874.874 0 0 1-.878-.87l.053.035z"
        opacity={0.5}
        fill="#A3ACD6"
        fillRule="nonzero"
      />
      <path
        d="M134.703 279.739c2.03.39 4.018.965 5.941 1.716l5.82 2.024 11.64 4.042 11.597 4.17 5.794 2.085a23.737 23.737 0 0 1 5.638 2.505c.215.15.27.444.121.66a.497.497 0 0 1-.434.206 24.235 24.235 0 0 1-5.968-1.621l-5.811-2.042-11.623-4.084-11.57-4.213-5.794-2.11a34.197 34.197 0 0 1-5.664-2.48.469.469 0 0 1-.177-.548.478.478 0 0 1 .49-.31zM178.045 257.614c-1.548 1.384-3.478 1.912-5.216 2.76l-5.416 2.352-10.857 4.645-10.9 4.55-5.442 2.275a32.182 32.182 0 0 1-5.598 1.938.428.428 0 0 1-.513-.33.423.423 0 0 1 .183-.44 31.794 31.794 0 0 1 5.259-2.708l5.407-2.37 10.822-4.731 10.857-4.637 5.45-2.292c1.835-.7 3.538-1.73 5.616-1.886a.5.5 0 1 1 .374.865l-.026.009zM213.002 237.214a10.197 10.197 0 0 1-2.91 1.398l-3.032 1.081-6.098 2.154-12.118 4.29-12.197 4.213-6.098 2.076-3.05 1.038c-1.025.371-2.105.574-3.197.601a.32.32 0 0 1-.322-.309c0-.094.045-.183.122-.24a9.944 9.944 0 0 1 2.866-1.536l3.023-1.09 6.046-2.187 12.118-4.29 12.153-4.213 6.099-2.111 3.04-1.055a10.43 10.43 0 0 1 3.154-.73.518.518 0 0 1 .371.14c.1.093.158.222.16.358.019.15-.029.3-.13.412zM169.696 220.071c1.82.173 3.612.564 5.335 1.167l5.206 1.63c3.47 1.081 6.94 2.214 10.34 3.32 3.402 1.107 6.88 2.274 10.307 3.433l5.145 1.716a22.585 22.585 0 0 1 4.988 2.205.375.375 0 0 1 .113.514.374.374 0 0 1-.338.172 22.622 22.622 0 0 1-5.344-1.158l-5.206-1.613c-3.47-1.064-6.94-2.18-10.358-3.27a453.779 453.779 0 0 1-10.315-3.431l-5.153-1.716a22.613 22.613 0 0 1-4.998-2.197.453.453 0 0 1-.239-.548.46.46 0 0 1 .517-.31v.086z"
        opacity={0.5}
        fill="#A3ACD6"
        fillRule="nonzero"
      />
      <path
        d="M161.296 297.703v-79.58"
        stroke="#A3ACD6"
        strokeWidth={2}
        opacity={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M209.596 281.274a109.884 109.884 0 0 1-11.071 4.398l-11.174 4.096-11.244 3.976-5.613 1.988a25.604 25.604 0 0 1-5.743 1.61.475.475 0 0 1-.519-.422.447.447 0 0 1 .208-.44 25.595 25.595 0 0 1 5.448-2.417l5.596-2.031 11.183-4.063 11.244-3.941c3.762-1.274 7.438-2.771 11.339-3.64a.472.472 0 1 1 .32.86l.026.026zM165.55 255.957a41.33 41.33 0 0 1 6.003 2.22l5.891 2.491 11.783 5.008 11.73 5.103 5.856 2.552a25.855 25.855 0 0 1 5.692 2.96.506.506 0 0 1 .086.724.494.494 0 0 1-.477.191 25.963 25.963 0 0 1-6.073-2.107l-5.874-2.526-11.756-5.042-11.695-5.156-5.857-2.612a41.903 41.903 0 0 1-5.735-2.857.532.532 0 0 1-.165-.731.537.537 0 0 1 .59-.218z"
        opacity={0.5}
        fill="#A3ACD6"
        fillRule="nonzero"
      />
      <path
        d="M165.004 279.303c2.414-.053 4.454.957 6.598 1.654l6.388 2.246 12.759 4.5 12.724 4.597c4.227 1.576 8.47 3.117 12.68 4.727l-1.168.818v-79.977a.871.871 0 0 1 1.743 0v79.977a.871.871 0 0 1-1.141.827c-4.27-1.445-8.524-2.95-12.785-4.43l-12.742-4.562-12.706-4.658-6.345-2.333c-2.1-.87-4.358-1.35-6.1-2.925a.27.27 0 0 1 0-.366.287.287 0 0 1 .095-.095z"
        opacity={0.5}
        fill="#A3ACD6"
        fillRule="nonzero"
      />
      <path
        d="M212.776 256.478a27.896 27.896 0 0 1-5.629 2.9l-5.794 2.528-11.588 5.05c-3.874 1.651-7.748 3.312-11.64 4.92l-5.837 2.435a32 32 0 0 1-6.08 1.927.315.315 0 0 1-.366-.25.308.308 0 0 1 .122-.31 31.253 31.253 0 0 1 5.577-3.027l5.794-2.53c3.857-1.72 7.73-3.337 11.605-4.997l11.675-4.87 5.837-2.425a28.339 28.339 0 0 1 5.994-2.065.417.417 0 0 1 .47.327.402.402 0 0 1-.14.387zM166.951 236.996a23.47 23.47 0 0 1 5.607 1.478l5.45 1.913 10.892 3.86 10.849 3.946 5.424 1.99a29.059 29.059 0 0 1 5.294 2.365c.177.115.23.35.121.53a.365.365 0 0 1-.373.174 28.233 28.233 0 0 1-5.59-1.521l-5.459-1.904-10.918-3.79-10.865-3.92-5.425-1.982a22.948 22.948 0 0 1-5.276-2.417.417.417 0 0 1-.113-.574.426.426 0 0 1 .382-.148zM208.112 221a27.267 27.267 0 0 1-5.23 2.18l-5.368 1.805-10.73 3.618c-3.59 1.164-7.172 2.356-10.772 3.503l-5.404 1.752a20.725 20.725 0 0 1-5.56 1.2.295.295 0 0 1-.323-.298.29.29 0 0 1 .131-.272 20.92 20.92 0 0 1 5.23-2.356l5.36-1.848c3.564-1.243 7.147-2.417 10.72-3.626l10.764-3.53 5.386-1.751a27.292 27.292 0 0 1 5.509-1.384c.3-.03.57.188.601.49a.543.543 0 0 1-.314.517z"
        opacity={0.5}
        fill="#A3ACD6"
        fillRule="nonzero"
      />
      <path
        d="M244.886 211.367c0 .619-.053 1.248-.075 1.876"
        fill="#93B9F4"
        fillRule="nonzero"
      />
      <path
        d="M244.81 134.121V278.05c0 11.023-8.97 19.96-20.036 19.96h-60.729c-8.894.01-16.73-5.82-19.244-14.32-.531-1.787-.81-3.679-.81 20.394V160.156c0-26.19.087-28.195.27-29.28a.588.588 0 0 1 0-.122c1.64-9.598 9.993-16.617 19.767-16.61h60.746c9.77-.002 18.118 7.015 19.757 16.61.195 1.112.288 2.239.28 3.367z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M175.591 159.19v1.427c0 1.216-.48 2.382-1.332 3.24a4.52 4.52 0 0 1-3.215 1.338h-2.224M157.41 165.195a4.716 4.716 0 0 1-4.39-4.69v-12.75c0-1.25.499-2.448 1.388-3.331a4.738 4.738 0 0 1 3.348-1.376h13.099a4.738 4.738 0 0 1 3.349 1.376 4.697 4.697 0 0 1 1.387 3.332v6.66M203.806 159.19v1.427c0 1.216-.48 2.382-1.333 3.24a4.52 4.52 0 0 1-3.215 1.338h-2.224M185.617 165.195a4.713 4.713 0 0 1-4.383-4.69v-12.75c0-1.25.499-2.448 1.386-3.33a4.727 4.727 0 0 1 3.343-1.377h13.079a4.727 4.727 0 0 1 3.366 1.364 4.701 4.701 0 0 1 1.398 3.344v6.66M233.525 159.19v1.427c0 2.529-2.035 4.578-4.545 4.578h-2.226M215.704 165.195a4.713 4.713 0 0 1-4.374-4.69v-12.75c0-1.255.503-2.46 1.397-3.343a4.727 4.727 0 0 1 3.366-1.364h13.088a4.714 4.714 0 0 1 4.72 4.708v6.66M175.591 190.345v1.428c0 1.216-.48 2.382-1.332 3.24a4.52 4.52 0 0 1-3.215 1.339h-2.224M157.41 196.352a4.716 4.716 0 0 1-4.39-4.69v-12.75c0-1.25.499-2.449 1.388-3.332a4.738 4.738 0 0 1 3.348-1.376h13.099a4.738 4.738 0 0 1 3.349 1.376 4.697 4.697 0 0 1 1.387 3.332v6.66M203.806 190.345v1.428c0 1.216-.48 2.382-1.333 3.24a4.52 4.52 0 0 1-3.215 1.339h-2.224"
        stroke="#A3ACD6"
        strokeWidth={2}
        opacity={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M185.626 197.687a5.57 5.57 0 0 1-4.102-2.274 5.798 5.798 0 0 1-.98-2.222 6.391 6.391 0 0 1-.112-1.203V180.678c0-.715 0-1.308.07-2.17.329-2.497 2.265-4.463 4.728-4.802a18.167 18.167 0 0 1 2.142-.043c1.361-.065 2.725.05 4.058.34a.483.483 0 0 1 .29.23.494.494 0 0 1 .045.37c-.04.169-.17.3-.335.34a15.228 15.228 0 0 1-4.058.34 16.03 16.03 0 0 0-1.91.07 3.668 3.668 0 0 0-1.547.602 3.875 3.875 0 0 0-1.72 2.78c-.06.496 0 1.28 0 1.943v11.328c-.006.292.017.584.069.871.326 1.704 1.732 2.98 3.44 3.12a.87.87 0 0 1 .795.937.866.866 0 0 1-.925.806l.052-.053z"
        opacity={0.5}
        fill="#A3ACD6"
        fillRule="nonzero"
      />
      <path
        d="M233.525 190.345v1.429c0 2.528-2.035 4.578-4.545 4.578h-2.226M215.704 196.352a4.713 4.713 0 0 1-4.374-4.69v-12.75c0-1.256.503-2.46 1.397-3.344a4.727 4.727 0 0 1 3.366-1.363h13.088a4.714 4.714 0 0 1 4.72 4.707v6.66M175.591 221.126v1.428c0 1.216-.48 2.382-1.332 3.24a4.52 4.52 0 0 1-3.215 1.338h-2.224M157.41 226.757a4.716 4.716 0 0 1-4.39-4.69v-12.75c0-1.25.499-2.448 1.388-3.331a4.738 4.738 0 0 1 3.348-1.376h13.099a4.738 4.738 0 0 1 3.349 1.376 4.697 4.697 0 0 1 1.387 3.331v6.66M175.591 252.283v1.421c0 1.217-.48 2.385-1.332 3.245a4.517 4.517 0 0 1-3.215 1.34h-2.224M157.41 258.289a4.725 4.725 0 0 1-4.39-4.7v-12.74c0-1.25.499-2.449 1.388-3.332a4.738 4.738 0 0 1 3.348-1.376h13.099a4.738 4.738 0 0 1 3.349 1.376 4.697 4.697 0 0 1 1.387 3.332v6.66M233.525 221.126v1.428c0 2.529-2.035 4.578-4.545 4.578h-2.226M215.712 226.757a4.717 4.717 0 0 1-4.382-4.69v-12.75c0-2.6 2.117-4.707 4.73-4.707h13.112c2.612 0 4.73 2.107 4.73 4.707v6.66"
        stroke="#A3ACD6"
        strokeWidth={2}
        opacity={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(68 93)">
        <path
          d="M177.688 183.959v2.66c0 10.972-8.93 19.866-19.947 19.866H97.295c-8.859.008-16.662-5.804-19.157-14.269v-8.257h99.55zM176.56 38.669H76.26c1.64-9.545 10-16.526 19.78-16.519h60.774c9.768.01 18.108 6.986 19.747 16.519z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M75.172 99.846a.924.924 0 0 1 0-.534c.06-.185.148-.362.26-.526a.966.966 0 0 1 .623-.405c.259-.05.529 0 .747.138.112.073.21.164.29.267.105.166.189.342.25.526.06.174.06.36 0 .534-.09.275-.293.505-.566.64a1.254 1.254 0 0 1-.89.082 1.133 1.133 0 0 1-.714-.722zM177.457 101.729c.818 11.68.522 23.352.592 35.032v48.173c.052 1.53-.033 3.06-.253 4.574a21.357 21.357 0 0 1-21.24 18.09H97.432a27.458 27.458 0 0 1-4.573-.243 21.408 21.408 0 0 1-16.98-14.298 21.104 21.104 0 0 1-1-4.566c-.08-.775-.12-1.552-.122-2.33v-2.184l.043-35.04V122.663a35.85 35.85 0 0 1 .644-8.758.66.66 0 0 1 .791-.478.643.643 0 0 1 .47.478 35.86 35.86 0 0 1 .652 8.758V186.16c0 .683.034 1.365.104 2.044a18.653 18.653 0 0 0 15.746 16.429c1.38.181 2.773.25 4.165.208H156.469c.704 0 1.365 0 2.051-.095a18.724 18.724 0 0 0 7.678-2.557 19.009 19.009 0 0 0 5.877-5.566 18.771 18.771 0 0 0 2.956-7.54c.2-1.377.278-2.768.235-4.158v-48.172c.07-11.681-.226-23.353.591-35.033a.73.73 0 1 1 1.461 0l.14.009zM75.354 94.238c-.218-4.58-.365-9.167-.513-13.755-.148-4.589-.052-9.177-.07-13.765V42.634v-1.792c0-.614.078-1.212.165-1.818.671-4.9 3.039-9.413 6.696-12.76a21.316 21.316 0 0 1 13.347-5.592c1.26-.052 2.365 0 3.53 0h58.804c2.468.06 4.906.555 7.2 1.463 7.008 2.74 12.043 8.96 13.242 16.361.192 1.206.285 2.424.279 3.645v37.917a24.24 24.24 0 0 1-.644 6.874.66.66 0 0 1-1.27 0 24.24 24.24 0 0 1-.643-6.874V42.141c0-1.079-.084-2.155-.252-3.22a18.604 18.604 0 0 0-11.634-14.293 18.33 18.33 0 0 0-6.278-1.255H95.11a18.684 18.684 0 0 0-11.651 4.865 18.543 18.543 0 0 0-5.87 11.141 19.576 19.576 0 0 0-.156 1.602c-.035.537 0 1.065 0 1.653v24.101c0 4.588 0 9.177-.07 13.765-.07 4.588-.295 9.176-.504 13.756a.729.729 0 0 1-.73.727.729.729 0 0 1-.73-.727l-.044-.018z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M119.372 29.797a23.452 23.452 0 0 1 3.605-.448c1.199-.08 2.397-.072 3.605-.054 2.408.016 4.813.183 7.2.502a.832.832 0 0 1 .702.896.812.812 0 0 1-.702.708 57.205 57.205 0 0 1-7.2.51c-1.208 0-2.406 0-3.605-.053a23.479 23.479 0 0 1-3.605-.457.84.84 0 0 1-.663-.825.84.84 0 0 1 .663-.824v.045zM132.275 198.197a5.947 5.947 0 0 1-3.501 3.06 7.3 7.3 0 0 1-4.902-.162 7.383 7.383 0 0 1-3.755-3.13 7.214 7.214 0 0 1-.963-4.715 7.373 7.373 0 0 1 2.215-4.244 7.515 7.515 0 0 1 4.324-1.997 7.065 7.065 0 0 1 4.569 1.037 6.365 6.365 0 0 1 2.687 3.558.46.46 0 0 1-.259.604.476.476 0 0 1-.617-.253c-1.227-2.123-3.663-3.286-6.127-2.923a5.285 5.285 0 0 0-2.958 1.569 4.994 4.994 0 0 0-1.227 4.514 5.095 5.095 0 0 0 3.065 3.58c.508.2 1.046.316 1.593.342a6.252 6.252 0 0 0 1.646-.111 7.725 7.725 0 0 0 3.142-1.5.702.702 0 0 1 .971.11.674.674 0 0 1 .097.66zM166.133 183.188c1.627-.339 3.28-.55 4.94-.633 1.648-.11 3.3-.127 4.95-.051.712 0 1.29.567 1.29 1.267s-.578 1.268-1.29 1.268c-1.65.076-3.302.059-4.95-.051a31.022 31.022 0 0 1-4.94-.634.602.602 0 0 1-.466-.583c0-.277.192-.517.466-.583zM149.04 183.442c.424-.283.91-.468 1.42-.542a1.347 1.347 0 0 1 1.43.387 1.02 1.02 0 0 1 0 1.341c-.344.406-.915.56-1.43.387a3.465 3.465 0 0 1-1.42-.541.613.613 0 0 1-.17-.86.709.709 0 0 1 .17-.172zM76.06 182.62c10.917-.176 21.835-.168 32.753-.159l16.382.132c5.454.08 10.917-.105 16.38.836.279.049.465.316.418.598a.507.507 0 0 1-.417.422c-5.464.95-10.927.757-16.381.88l-16.382.132c-10.918 0-21.836 0-32.754-.159-.72 0-1.302-.59-1.302-1.319s.583-1.32 1.302-1.32v-.043zM87.302 37.924c7.397-.675 14.759-.586 22.181-.643l22.173-.114h22.173l22.18.09c.72 0 1.303.546 1.303 1.22 0 .674-.583 1.22-1.302 1.22l-22.181.098h-22.173l-22.173-.114c-7.396-.057-14.758 0-22.18-.642a.586.586 0 0 1-.395-.195.514.514 0 0 1-.127-.4.561.561 0 0 1 .521-.52z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path d="M237.794 206.86h4.883" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M237.483 206.448a6.837 6.837 0 0 1 2.488-.33c.839 0 1.676.08 2.497.237.342.072.585.36.585.693 0 .332-.243.62-.585.692a13.15 13.15 0 0 1-2.497.237 6.837 6.837 0 0 1-2.488-.33.662.662 0 0 1-.383-.343.613.613 0 0 1-.008-.501.67.67 0 0 1 .391-.355z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path d="M14.65 206.86h219.387" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M14.486 206.491c9.14-.286 18.264-.334 27.405-.334h27.396l54.792-.048h82.189c9.132 0 18.264 0 27.396.287a.727.727 0 0 1 .54.256c.14.162.215.38.208.603-.009.449-.338.81-.748.82-9.132.249-18.264.277-27.396.287h-82.189l-54.792-.048H41.89c-9.133 0-18.265 0-27.405-.334a.561.561 0 0 1-.407-.2.676.676 0 0 1-.159-.459.71.71 0 0 1 .099-.566.585.585 0 0 1 .467-.264z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path d="M9.016 206.86h.616" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M9.245 206.156c.133-.014.268-.014.402 0a.823.823 0 0 1 .205 0c.071.014.138.043.196.084a.935.935 0 0 1 .451.613.898.898 0 0 1-.166.73 1.057 1.057 0 0 1-.285.262.522.522 0 0 1-.196.084c-.067.02-.136.036-.205.047a1.924 1.924 0 0 1-.402 0c-.542 0-.98-.418-.98-.933 0-.516.438-.934.98-.934v.047z"
          fill="#062342"
          fillRule="nonzero"
        />
        <path d="M.376 206.86h3.38" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M.449 206.376a7.923 7.923 0 0 1 1.791-.249 4.71 4.71 0 0 1 1.748.175c.387.133.604.566.49.977a.8.8 0 0 1-.49.516 4.901 4.901 0 0 1-1.748.175 8.481 8.481 0 0 1-1.748-.248c-.35-.1-.56-.477-.472-.848a.715.715 0 0 1 .429-.498z"
          fill="#062342"
          fillRule="nonzero"
        />
        <path d="M98.799 218.874h68.37" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M98.958 218.188c2.863-.297 5.725-.335 8.578-.345h8.587l17.157-.096h25.744c2.862 0 5.716 0 8.578.278.395.047.694.413.694.849s-.3.802-.694.848c-2.862.25-5.716.268-8.578.278H133.28l-17.157-.096h-8.587c-2.853 0-5.715-.048-8.578-.345-.325-.035-.562-.352-.532-.71-.01-.34.223-.63.532-.66z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path d="M70.624 218.874h17.28" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M70.81 218.007c2.928-.167 5.856-.222 8.784-.25 2.927-.028 5.855 0 8.774.176a.69.69 0 0 1 .487.253c.124.15.187.346.175.545-.018.38-.305.683-.662.696-2.919.205-5.847.214-8.774.186-2.928-.028-5.856-.093-8.784-.26-.32-.04-.561-.33-.561-.673s.241-.632.561-.673z"
          fill="#717791"
          fillRule="nonzero"
        />
        <g opacity={0.5} fill="#A3ACD6" fillRule="nonzero">
          <path d="M141.317 2.386c.006.484.05.967.13 1.444.06.474.095.95.105 1.427 0 .57-.139 1.254-1.398 1.27v1.16c1.259 0 1.398.743 1.398 1.262-.01.471-.044.942-.104 1.41-.08.47-.124.948-.13 1.426 0 1.798 1.11 2.447 2.665 2.447h.52v-1.263h-.32c-.869 0-1.25-.518-1.25-1.409.008-.377.048-.753.12-1.124 0-.39.114-.865.114-1.297a1.724 1.724 0 0 0-.292-1.307 1.737 1.737 0 0 0-1.14-.708 1.737 1.737 0 0 0 1.133-.701c.269-.375.376-.842.299-1.296 0-.467-.078-.864-.113-1.288a6.442 6.442 0 0 1-.122-1.124c0-.865.322-1.392 1.25-1.392h.34V0H144c-1.606 0-2.683.709-2.683 2.386zM159.28 7.686V6.528c-1.258 0-1.397-.7-1.397-1.271.01-.477.044-.953.104-1.427.074-.478.114-.96.121-1.444C158.108.71 157.04 0 155.443 0h-.504v1.28h.33c.93 0 1.25.518 1.25 1.392-.006.378-.05.754-.13 1.124 0 .397-.104.864-.104 1.288-.078.454.03.92.299 1.296.269.375.677.627 1.134.701-.46.074-.872.33-1.141.708-.27.379-.375.85-.292 1.307 0 .475.07.864.104 1.297.08.37.123.746.13 1.124 0 .864-.355 1.41-1.25 1.41h-.33v1.27h.521c1.563 0 2.666-.648 2.666-2.447a10.334 10.334 0 0 0-.122-1.426 13.14 13.14 0 0 1-.104-1.41c-.009-.484.13-1.21 1.38-1.228zM149.73 9.51a.94.94 0 0 0-.693.286.932.932 0 0 0-.262.7c0 .53.432.96.964.96s.964-.43.964-.96a.932.932 0 0 0-.268-.707.94.94 0 0 0-.705-.278zM150.312 8.68l.226-5.905h-1.65l.226 5.906z" />
        </g>
        <path
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M207.365 75.085h14.275V197.85h-14.275zM201.73 66.826h26.296v8.259H201.73z"
        />
        <path
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M196.847 197.474h34.937v9.386h-34.937zM265.968 52.56H130.594l-8.88 14.266h144.254zM146.884 52.56l-8.64 14.266"
        />
        <path
          d="M161.528 56.566a7.22 7.22 0 0 1-1.118 2.785c-.502.854-1.017 1.708-1.543 2.563-1.065 1.708-2.147 3.331-3.354 4.92a1.084 1.084 0 0 1-1.371.213.996.996 0 0 1-.403-1.28c.95-1.71 2.014-3.418 3.096-5.067.55-.854 1.118-1.648 1.686-2.46a7.494 7.494 0 0 1 2.102-2.204.604.604 0 0 1 .817.154c.077.11.109.244.088.376z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M176.56 57.065l-5.634 9.386"
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.588 57.492a73.041 73.041 0 0 1-2.853 4.848 82.634 82.634 0 0 1-1.56 2.344 5.304 5.304 0 0 1-2.006 2.064.66.66 0 0 1-.891-.272.642.642 0 0 1-.072-.308 5.22 5.22 0 0 1 .99-2.696c.472-.808.954-1.615 1.453-2.406.99-1.59 2.006-3.17 3.147-4.671a1.062 1.062 0 0 1 1.429-.299c.48.296.637.913.354 1.396h.009z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M233.286 52.56l-6.01 10.136"
          fill="#E5EEFD"
          fillRule="nonzero"
        />
        <path
          d="M233.915 53.69c-.463.864-.962 1.658-1.48 2.47a87.036 87.036 0 0 1-1.595 2.401c-.543.795-1.114 1.572-1.675 2.35a8.04 8.04 0 0 1-2.095 2.098.356.356 0 0 1-.49-.086.379.379 0 0 1-.054-.242 8.034 8.034 0 0 1 1.016-2.747c.473-.863.945-1.675 1.444-2.496.5-.82.99-1.64 1.516-2.444.526-.803 1.051-1.606 1.63-2.375a1.06 1.06 0 0 1 1.462-.242c.43.299.567.859.321 1.313z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M247.56 55.939l-6.385 10.512"
          fill="#E5EEFD"
          fillRule="nonzero"
        />
        <path
          d="M247.926 56.431a6.147 6.147 0 0 1-1.054 2.967c-.51.87-1.029 1.74-1.565 2.663-1.072 1.74-2.17 3.48-3.41 5.142a1.053 1.053 0 0 1-1.358.216 1.03 1.03 0 0 1-.399-1.304c.95-1.827 2.04-3.576 3.146-5.307.563-.87 1.134-1.74 1.758-2.558a6.224 6.224 0 0 1 2.223-2.254.477.477 0 0 1 .641.183c.03.08.037.168.018.252z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M263.339 57.44l-5.635 9.01"
          fill="#E5EEFD"
          fillRule="nonzero"
        />
        <path
          d="M263.339 57.67a7.526 7.526 0 0 1-.904 2.577c-.422.776-.844 1.56-1.275 2.337a58.775 58.775 0 0 1-2.761 4.53.964.964 0 0 1-1.354.303c-.455-.3-.603-.927-.335-1.418.844-1.578 1.689-3.112 2.626-4.62.465-.748.946-1.488 1.427-2.229a6.91 6.91 0 0 1 1.731-1.997.49.49 0 0 1 .701.152c.086.1.137.23.144.365zM138.248 67.12c-1.077-1.614-2.067-3.273-3.049-4.94-.486-.878-.964-1.685-1.433-2.528a9.903 9.903 0 0 1-1.146-2.712.518.518 0 0 1 .39-.614.491.491 0 0 1 .426.105 9.784 9.784 0 0 1 1.902 2.23c.539.807 1.086 1.606 1.607 2.422 1.05 1.623 2.084 3.256 3.04 4.94.275.484.123 1.1-.345 1.396a1.018 1.018 0 0 1-1.392-.298zM154.726 67.128a138.26 138.26 0 0 1-3.155-4.975l-1.506-2.522a12.212 12.212 0 0 1-1.291-2.652.544.544 0 0 1 .249-.576.592.592 0 0 1 .647.015 12.556 12.556 0 0 1 1.927 2.289l1.686 2.418c1.102 1.624 2.196 3.256 3.226 4.923a.998.998 0 0 1-.355 1.374 1.082 1.082 0 0 1-1.437-.294h.009z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M171.677 66.826l-8.64-14.266"
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M187.079 67.118a83.896 83.896 0 0 1-3.318-5.104c-.523-.867-1.045-1.734-1.55-2.6a6.3 6.3 0 0 1-1.141-2.869.57.57 0 0 1 .853-.528 6.207 6.207 0 0 1 2.082 2.288c.574.866 1.132 1.733 1.68 2.539a109.862 109.862 0 0 1 3.135 5.2 1.02 1.02 0 0 1-.345 1.377 1.03 1.03 0 0 1-1.396-.294v-.009z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M204.36 66.826l-8.64-14.266M241.55 66.826l-8.64-14.266"
          stroke="#717791"
          strokeWidth={2}
          fill="#E5EEFD"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M220.938 83.988a55.681 55.681 0 0 1-2.56-1.384 59.848 59.848 0 0 1-2.51-1.483c-.803-.511-1.657-1.039-2.476-1.566a8.485 8.485 0 0 1-2.22-1.961.177.177 0 0 1 0-.248.247.247 0 0 1 .128 0c1.006.131 1.98.433 2.877.89.854.42 1.759.824 2.63 1.286.87.461 1.707.89 2.56 1.351.854.462 1.708.948 2.562 1.467a.965.965 0 0 1 .44.612.937.937 0 0 1-.142.732c-.292.396-.84.525-1.289.304z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M207.365 91.98l14.275-8.26"
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M220.937 100.883a96.274 96.274 0 0 1-5.346-3.147c-.853-.548-1.706-1.114-2.627-1.671a13.861 13.861 0 0 1-2.439-1.998.6.6 0 0 1 0-.804.549.549 0 0 1 .512-.142c1.001.284 1.968.684 2.882 1.193.904.504 1.817 1 2.712 1.521a99.388 99.388 0 0 1 5.295 3.236c.225.148.384.384.442.654.057.27.009.553-.135.786a.994.994 0 0 1-1.296.372z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M207.365 108.123l14.275-8.26M221.64 116.007l-14.275-8.26"
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.594 123.597a49.306 49.306 0 0 1 4.432-2.65c.748-.416 1.515-.85 2.29-1.198a4.663 4.663 0 0 1 2.568-.739.54.54 0 0 1 .496.561.5.5 0 0 1-.052.195 4.473 4.473 0 0 1-1.933 1.801c-.731.467-1.463.91-2.203 1.342a50.743 50.743 0 0 1-4.57 2.421 1.04 1.04 0 0 1-1.241-.43.985.985 0 0 1 .213-1.269v-.034z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M221.64 132.526l-14.275-8.26M207.365 141.16l14.275-8.259M221.64 149.044l-14.275-8.259"
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.568 156.274c1.485-.97 3.042-1.82 4.608-2.646.787-.405 1.583-.843 2.388-1.197a5.066 5.066 0 0 1 2.684-.759c.181.015.343.111.434.259.09.148.1.328.023.483a4.77 4.77 0 0 1-2.022 1.787c-.76.463-1.521.91-2.29 1.348-1.548.843-3.105 1.685-4.733 2.444-.48.166-1.016-.014-1.275-.428a.954.954 0 0 1 .219-1.258l-.036-.033z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M216.675 162.919a9.18 9.18 0 0 1-2.73-.862c-.878-.422-1.677-.862-2.502-1.284-1.65-.862-3.283-1.784-4.846-2.81a1.004 1.004 0 0 1-.215-1.285 1.045 1.045 0 0 1 1.25-.437c1.686.81 3.302 1.723 4.908 2.714.8.474 1.58.99 2.37 1.49a9.136 9.136 0 0 1 2.143 1.888.392.392 0 0 1-.08.551.375.375 0 0 1-.298.035z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M207.365 173.823l14.275-8.26M221.64 181.331l-14.275-8.26M220.513 197.099l-13.148-7.509"
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M258.455 66.826l-8.64-13.89"
          stroke="#717791"
          strokeWidth={2}
          fill="#E5EEFD"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M223.143 44.3l-4.587-22.525h-7.81l-4.884 22.455"
        />
        <path
          d="M207.241 180.732l7.253.098H221.403l-.132.045-.106.063c-.3.194-.48.532-.475.894l1.567.894a89.31 89.31 0 0 1-5.114 3.2c-.88.5-1.76 1-2.64 1.474a5.684 5.684 0 0 1-2.87 1.064.585.585 0 0 1-.414-.182.604.604 0 0 1-.167-.426.661.661 0 0 1 .062-.268 5.814 5.814 0 0 1 2.315-2.11c.836-.554 1.69-1.09 2.543-1.626a84.42 84.42 0 0 1 5.229-3.004h.07c.241-.128.523-.153.783-.069.26.084.475.27.599.516.076.156.115.327.114.5-.009.363-.193.697-.493.895l-.088.044h-.053l-.184.054H214.547l-7.27-.098a1.047 1.047 0 0 1-1.039-1.055c0-.583.465-1.055 1.039-1.055l-.036.152zM207.304 164.177a75.312 75.312 0 0 1 3.34-.108c1.11-.017 2.228 0 3.338 0 1.11 0 2.22.074 3.34.132a7.665 7.665 0 0 1 3.338.688.404.404 0 0 1 .184.547.385.385 0 0 1-.192.174 7.643 7.643 0 0 1-3.34.646c-1.118 0-2.228.058-3.347.058h-3.348c-1.11 0-2.229-.083-3.347-.174-.57 0-1.032-.438-1.032-.978s.462-.977 1.032-.977l.034-.008z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M207.365 149.044s14.275.218 14.275 0M207.365 132.901s14.275.218 14.275 0M207.365 116.007s6.647.097 10.835.09h3.44M207.365 99.863s14.275.218 14.275 0M207.365 83.72s14.275.218 14.275 0M147.26 52.184l63.373-30.41 9.876 11.367h-12.064l14.322 10.836"
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M218.635 21.775l47.333 30.41"
          stroke="#717791"
          strokeWidth={2}
          fill="#E5EEFD"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M119.836 66.826h26.296v8.259h-26.296z"
        />
        <ellipse
          stroke="#717791"
          strokeWidth={2}
          fill="#E5EEFD"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx={132.984}
          cy={97.611}
          rx={5.635}
          ry={5.631}
        />
        <path
          d="M133.205 99.488a1.88 1.88 0 0 1 .208-3.753 1.88 1.88 0 0 1 1.825 1.881"
          fill="#E5EEFD"
          fillRule="nonzero"
        />
        <path
          d="M133.043 100.207c-1.054.18-2.088-.42-2.483-1.445a2.786 2.786 0 0 1 .73-3.136 2.695 2.695 0 0 1 3.012-.328c.9.477 1.416 1.482 1.294 2.518a.466.466 0 0 1-.177.305.438.438 0 0 1-.334.086.482.482 0 0 1-.378-.363 1.437 1.437 0 0 0-.995-1.11 1.02 1.02 0 0 0-1.118.382 1.516 1.516 0 0 0-.3 1.055c.07.515.37.966.81 1.218h.053a.45.45 0 0 1-.114.809v.009z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path d="M137.116 93.857v-13.14" fill="#E5EEFD" fillRule="nonzero" />
        <path
          d="M135.99 93.495v-3.256c0-1.088 0-2.176.065-3.264a45.157 45.157 0 0 1 .438-6.528c.062-.311.38-.522.718-.475a.611.611 0 0 1 .504.475c.302 2.165.452 4.346.448 6.528 0 1.088 0 2.176.056 3.264.055 1.088 0 2.177 0 3.256a.997.997 0 0 1-.517.96 1.176 1.176 0 0 1-1.159 0 .997.997 0 0 1-.516-.96h-.038z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M128.852 75.085v19.523"
          stroke="#717791"
          strokeWidth={2}
          fill="#E5EEFD"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.868 111.862c-.428 2.372-2.599 4.006-4.971 3.741-2.373-.265-4.138-2.339-4.041-4.748.096-2.41 2.021-4.332 4.407-4.403v-3.21"
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M120.963 122.39l11.695-9.011 11.22 9.01"
        />
        <rect
          fill="#FFF"
          fillRule="nonzero"
          x={114.201}
          y={122.014}
          width={38.317}
          height={39.044}
          rx={6.26}
        />
        <rect
          fill={props.theme.accent}
          fillRule="nonzero"
          x={114.201}
          y={124.642}
          width={38.317}
          height={36.041}
          rx={6.26}
        />
        <path
          d="M152.518 130.632v23.566a6.059 6.059 0 0 1-1.808 4.32 6.227 6.227 0 0 1-4.375 1.79h-25.933a6.236 6.236 0 0 1-4.384-1.787 6.068 6.068 0 0 1-1.817-4.323v-2.068c.83 2.518 3.212 4.22 5.896 4.214h25.932c3.414-.005 6.183-2.732 6.192-6.1V128.77c.201.6.302 1.23.297 1.861z"
          fill="#3F72BA"
          fillRule="nonzero"
          opacity={0.2}
        />
        <path
          d="M112.032 136.09a1.31 1.31 0 0 1-.075-.371 1.756 1.756 0 0 1 0-.379c.019-.128.06-.253.122-.37.072-.137.166-.265.28-.378a.83.83 0 0 1 1.055 0c.113.113.208.24.28.378.062.117.103.242.121.37.014.126.014.253 0 .379a1.064 1.064 0 0 1-.084.37c-.126.338-.483.566-.887.566-.403 0-.76-.228-.887-.566h.075z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          d="M112.413 131.102a7.697 7.697 0 0 1-.358-2.103v-1.139a7.96 7.96 0 0 1 .218-1.208 7.233 7.233 0 0 1 4.872-5.064 7.737 7.737 0 0 1 2.366-.324h27.229a7.27 7.27 0 0 1 5.998 3.793c.392.73.663 1.52.803 2.34.065.405.1.815.105 1.226v27.392c-.056.41-.137.817-.245 1.217-.238.797-.6 1.551-1.074 2.234a7.404 7.404 0 0 1-3.972 2.812c-.398.11-.804.188-1.213.237-.39.035-.78.05-1.17.044h-27.265a7.265 7.265 0 0 1-6.643-6.238 7.163 7.163 0 0 1-.079-1.2v-3.258c-.12-2.807.044-5.619.489-8.392a.604.604 0 0 1 .716-.465.63.63 0 0 1 .463.465c.44 2.774.604 5.585.489 8.392v3.144c.002.293.022.586.06.876a5.06 5.06 0 0 0 1.58 2.996 4.985 4.985 0 0 0 3.091 1.35H146.888c.295-.036.586-.092.873-.167a5.099 5.099 0 0 0 2.724-1.945c.323-.468.568-.986.725-1.533.084-.286.14-.579.166-.876V128.614a4.562 4.562 0 0 0-.07-.876 5.066 5.066 0 0 0-.541-1.62 5.166 5.166 0 0 0-2.489-2.26 4.927 4.927 0 0 0-1.658-.386h-6.216l-16.727-.088h-4.182a5.7 5.7 0 0 0-1.746.22 5.458 5.458 0 0 0-1.545.779 5.072 5.072 0 0 0-1.93 2.82c-.079.287-.134.58-.165.876V129.034a7.697 7.697 0 0 1-.358 2.103.699.699 0 0 1-1.319 0l-.017-.035z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M265.968 46.928h8.64v24.778h-8.64z"
        />
        <path d="M283.249 64.765v6.941h-8.64V46.928h8.64V58.18" />
        <path
          d="M283.346 64.8c.38 1.128.58 2.31.594 3.5a23.295 23.295 0 0 1-.044 3.5v.105c-.05.528-.493.93-1.022.928h-8.737a1.03 1.03 0 0 1-1.031-1.033V46.835c0-.57.461-1.033 1.03-1.033h8.738c.546 0 .998.425 1.03.971v.062c.097 1.89.114 3.78.08 5.67a28.158 28.158 0 0 1-.472 5.662.648.648 0 0 1-.778.498.656.656 0 0 1-.498-.498 28.807 28.807 0 0 1-.472-5.662c0-1.89 0-3.78.079-5.67l1.022.997h-8.737l1.031-1.032V71.79l-1.031-1.032h8.737l-1.03 1.032a24.54 24.54 0 0 1 0-3.5c.013-1.19.213-2.372.593-3.5a.507.507 0 0 1 .655-.298.525.525 0 0 1 .263.307z"
          fill="#717791"
          fillRule="nonzero"
        />
        <path
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M204.422 66.45l-6.448-10.17 7.035-11.98h19.26v22.003z"
        />
        <path
          d="M220.843 48.056H207.58l-4.723 8.258h17.986s.103-8.4 0-8.258z"
          stroke="#717791"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M123.217 138.157c.084-1.475 1.32-2.628 2.817-2.628s2.734 1.153 2.818 2.628M135.99 138.157c.084-1.475 1.32-2.628 2.817-2.628s2.733 1.153 2.817 2.628"
          stroke="#717791"
          strokeWidth={2.36}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M136.365 144.915c-.137 1.903-1.778 3.379-3.757 3.379-1.978 0-3.619-1.476-3.756-3.38h7.513z"
          stroke="#717791"
          strokeWidth={2.36}
          fill="#FFF"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          fill="#3F72BA"
          fillRule="nonzero"
          opacity={0.2}
          cx={143.503}
          cy={143.788}
          rx={2.63}
          ry={2.628}
        />
        <ellipse
          fill="#3F72BA"
          fillRule="nonzero"
          opacity={0.2}
          cx={121.339}
          cy={143.788}
          rx={2.63}
          ry={2.628}
        />
        <rect
          stroke="#717791"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          x={34.185}
          y={199.352}
          width={23.667}
          height={6.382}
          rx={1.69}
        />
        <rect
          stroke="#717791"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          x={36.439}
          y={192.594}
          width={23.667}
          height={6.382}
          rx={1.69}
        />
        <rect
          fill="#E5EEFD"
          fillRule="nonzero"
          x={35.688}
          y={186.962}
          width={23.667}
          height={4.505}
          rx={1.69}
        />
        <rect
          fill="#FFF"
          fillRule="nonzero"
          x={36.063}
          y={188.464}
          width={23.667}
          height={4.505}
          rx={1.69}
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
          d="M37.566 194.096H59.73v1.126H37.566z"
        />
        <path
          fill="#3F72BA"
          fillRule="nonzero"
          opacity={0.2}
          d="M34.937 200.102h22.164v1.126H34.937z"
        />
        <path
          d="M35.505 186.22a2.477 2.477 0 0 1 1.25-.704c.209-.044.423-.062.637-.053h21.036a2.535 2.535 0 0 1 2.43 2.334v4.113A2.548 2.548 0 0 1 59.03 194c-.232.06-.47.093-.708.096H37.314c-.47.004-.93-.127-1.329-.378a2.366 2.366 0 0 1-.874-1.075 2.1 2.1 0 0 1-.174-.678v-.564c.043-.29.07-.581.13-.88a.736.736 0 0 1 .726-.627c.362 0 .67.266.725.626.061.282.088.573.131.881v.291l.08.15a.61.61 0 0 0 .54.255H58.289a.529.529 0 0 0 .13 0 .428.428 0 0 0 .21-.141.416.416 0 0 0 .097-.22v-2.45a9.82 9.82 0 0 0 0-1.33.428.428 0 0 0-.385-.272H37.182a.592.592 0 0 0-.235.114l-.08.062a1 1 0 0 1-1.415-.167 1.035 1.035 0 0 1 .053-1.472z"
          fill="#717791"
          fillRule="nonzero"
        />
        <rect
          fill="#FFF"
          fillRule="nonzero"
          x={61.984}
          y={180.58}
          width={23.667}
          height={4.505}
          rx={1.69}
        />
        <rect
          fill="#FFF"
          fillRule="nonzero"
          x={61.984}
          y={182.457}
          width={23.667}
          height={4.505}
          rx={1.69}
        />
        <path
          d="M61.776 179.469a2.435 2.435 0 0 1 1.232-.704 3.15 3.15 0 0 1 .63-.062h20.744a2.494 2.494 0 0 1 2.232 1.61c.084.23.139.47.164.714v4.11c-.173 1.268-1.238 2.209-2.49 2.2H63.567c-.464.005-.92-.13-1.31-.386a2.357 2.357 0 0 1-1.025-1.743v-.572c0-.282.069-.573.12-.88a.737.737 0 0 1 .72-.609c.352 0 .654.255.72.608.051.29.077.581.12.88v.3a.54.54 0 0 0 .078.14.599.599 0 0 0 .534.256h20.883a.465.465 0 0 0 .207-.14.418.418 0 0 0 .094-.22v-2.457c.03-.44.03-.88 0-1.32a.44.44 0 0 0-.379-.282H64.06a5.493 5.493 0 0 0-.63 0 .487.487 0 0 0-.232.115l-.078.061a.985.985 0 0 1-1.332-.13 1.037 1.037 0 0 1-.012-1.366v-.123z"
          fill="#717791"
          fillRule="nonzero"
        />
        <rect
          stroke="#717791"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          x={61.608}
          y={199.352}
          width={23.667}
          height={6.382}
          rx={1.69}
        />
        <rect
          stroke="#717791"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          x={64.238}
          y={192.594}
          width={23.667}
          height={6.382}
          rx={1.69}
        />
        <rect
          stroke="#717791"
          strokeWidth={2}
          fill="#FFF"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          x={61.608}
          y={186.587}
          width={23.667}
          height={6.382}
          rx={1.69}
        />
        <path
          fill="#3F72BA"
          fillRule="nonzero"
          opacity={0.2}
          d="M62.735 187.338h22.164v1.126H62.735zM64.989 193.72h22.164v1.126H64.989zM62.735 200.478h22.164v1.126H62.735z"
        />
      </g>
    </g>
  </svg>
)

export default withTheme(SvgApp)

import React, { PureComponent } from 'react'
import { Grid, Col, Row } from 'styles/flexboxgrid'
import Head from './components/head'
import Mail from './components/mail'
import Information from './components/information'
import NavFooter from './components/navfooter'
import SvgFooter from './components/svg/SvgFooter'

class Footer extends PureComponent {
  render () {
    return (
      <div style={{ zIndex: '-1' }}>
        <SvgFooter full />
        <footer ref={this.svgRef} style={{ marginTop: '10rem', zIndex: 2 }}>
          <Grid>
            <Row>
              <Col sm="6">
                <Head
                  title="Let's schedule a call"
                  subtitle="And get to know each other"
                />
                <Mail mail="max.fortarezza@gmail.com" to="/contatti" />
              </Col>
              <Col sm="6">
                <NavFooter />
              </Col>
            </Row>
            <Information
              facebook_to="https://www.facebook.com/fluent.digital.studio/"
              instagram_to="https://www.instagram.com/fluent.studio/"
              dribbble_to="https://dribbble.com/fluentdigitalstudio"
            />
          </Grid>
        </footer>
      </div>
    )
  }
}

export default Footer
